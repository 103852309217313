import React, { useState, useEffect, useCallback } from "react";
import CredentialsForm from "./CredentialsForm";
import { Button, useDataProvider, useRecordContext } from "react-admin";
import { CircularProgress } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import { Entry } from "./common";

export const MarketplaceListComponent: React.FC = (props) => {
  const [entries, setEntries] = useState<Entry[]>([]);
  const [selectedEntry, setSelectedEntry] = useState<Entry | null>(null);
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  const [loading, setLoading] = useState<boolean>(false);

  const getEntries = useCallback (() => {
    setLoading(true);
    dataProvider
      .getMarketplaces({ orgKey: record.organizationKey })
      .then((data: any) => {
        setEntries(data.json);
        setLoading(false);
      });
  }, [dataProvider, record.organizationKey]);

  useEffect(() => {
    getEntries();
  }, [getEntries]);

  const onDone = () => {
    setSelectedEntry(null);
    getEntries();
  };

  return (
    <div>
      {selectedEntry ? (
        <CredentialsForm marketplace={selectedEntry} onDone={onDone} />
      ) : (
        <>
          <h3>Marketplaces</h3>
          {loading && <CircularProgress />}

          <List dense={true}>
            {entries.map((entry) => (
              <>
                <ListItem
                  secondaryAction={
                    <>
                      <IconButton edge="end" aria-label="edit">
                        <EditIcon onClick={() => setSelectedEntry(entry)} />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText
                    primary={entry.name}
                    secondary={entry.abbreviation ? entry.abbreviation : null}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
          <Button
            label="Add new"
            variant="contained"
            size="small"
            sx={{ marginTop: "1rem", width: "10rem" }}
            onClick={() =>
              setSelectedEntry({
                name: "",
                abbreviation: ""
                
              })
            }
          />
        </>
      )}
    </div>
  );
};
