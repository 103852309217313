import {
    useDataProvider,
    useGetList,
    useRecordContext,
} from "react-admin";

import {useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextFieldMui from '@mui/material/TextField';
import {CircularProgress, Typography} from "@mui/material";

 interface SectionAutoCompleteProps {
    venueId: string;
    venueName: string;
    source: string;
    label?: string;
    sortable?: boolean;
}
const SectionAutoComplete: React.FC<SectionAutoCompleteProps> = (props) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const { venueId, venueName } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [saveStatusMessage, setSaveStatusMessage] = useState<string>("");
    const [saveStatus, setSaveStatus] = useState<string>("");

    interface SectionOptionType {
        id: number;
        masterVenueId: number;
        sectionName: string;

    }
    const { data, isLoading: isLoadingChoices } = useGetList<SectionOptionType>('master-venue-section', {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: 'sectionName', order: 'ASC' },
        filter: { masterVenueId: venueId?.toString() }
    });
    const choices: SectionOptionType[] = data as SectionOptionType[];
    const selectedChoice = choices?.find((option) => option.id === record.masterVenueSectionId);

    const handleChange = (event: any, value: any) => {
        setIsSaving(true);
        if (value.masterVenueSectionId !== record.masterVenueSectionId) {
            dataProvider.update('section-mapping-section', {
                id: record.id,
                data: {
                    id: record.id,
                    masterVenueSectionId: value.id,
                    sectionName: record.sectionName,
                    masterVenueId: venueId,
                    VenueName: venueName
                },
                previousData: {}
            }).then((response: any) => {
                setSaveStatusMessage("Saved");
                setSaveStatus("success");

            }).catch((error: any) => {
                console.error("Error updating section mapping:", error);
                setSaveStatusMessage(`Error: ${error.message}`);
                setSaveStatus("failed");

            }).finally(() => {
                setIsSaving(false);
            });
        }
    }
    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "end"}} >
            {isLoadingChoices && <CircularProgress />}
            {choices &&
                <Autocomplete
                    options={choices}
                    getOptionLabel={(option: SectionOptionType) => option.sectionName}
                    onChange={handleChange}
                    loading={isLoadingChoices}
                    clearOnEscape
                    sx={{ width: "45%" }}
                    value={selectedChoice}
                    renderInput={(params) => (
                        <TextFieldMui {...params} label="Select section" variant="standard"/>
                    )}
                />
            }
            <div>
                {isSaving && <>
                    <Typography variant="body1" paragraph={true}>Saving...</Typography>
                    <CircularProgress />
                </>
                }
                {saveStatus && !isSaving && <Typography variant="body1" style={{fontWeight:"bold", color: saveStatus === "success" ? "green" : "red"}}>{saveStatusMessage}</Typography>
                }
            </div>
        </div>
    )
}
export default SectionAutoComplete;