import { NotificationStatuses } from "../../utils/constants";

export const notificationStatusChoices = [
  { id: NotificationStatuses.Delivered, name: "Delivered" },
  { id: NotificationStatuses.Canceled, name: "Canceled" },
];

export const deliveryMethodChoices = [
  { id: "EMAIL", name: "EMAIL" },
  { id: "SMS", name: "SMS" },
  { id: "PUSH", name: "PUSH" },
];
