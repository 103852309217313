import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {format, parseISO} from 'date-fns';
import {CircularProgress, Typography} from "@mui/material";
import {
    Button,
    useNotify,
    useDataProvider
} from 'react-admin';

import {SourceProductionMasterMapped} from "../../utils/constants";
import {FaParking, FaTicketAlt} from "react-icons/fa";

import {Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import {DateTimeDisplay} from "../../utils/CombinedDateTimeField";
import RefreshIcon from '@mui/icons-material/Refresh';


const ActionButton = (props: ActionButtonProps) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [mappingSuccessful, setMappingSuccessful] = useState(false);
    const {id, productionMappingDetailsFormProps} = props;

    const handleMapClick = async (classification : string) => {
        try {

            const data: SourceProductionMasterMapped = {
                classification: classification,
                sourceProductionKey: productionMappingDetailsFormProps.sourceProductionKey,
                masterProductionId: id,
                sourceType: productionMappingDetailsFormProps.sourceType
            };
            await dataProvider.create('source-production-mappings', {data: data});
            notify('Mapping created successfully', {type: 'info'});
            setMappingSuccessful(true);
        } catch (error) {
            const err = error as Error;
            notify(`Error creating mapping: ${err.message}`, {type: 'error'});
        }
    };

    const disableMapButton = mappingSuccessful;

    return (
        <div style={{display: 'inline-flex', alignItems: 'center'}}>

            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick('Main')} style={{minWidth: 'auto', padding: '6px', margin: '0'}}>
                <Tooltip title="Main">
                    <span style={{display: 'inline-block', transform: 'rotate(90deg)'}}>
                    <FaTicketAlt/>
                  </span>
                </Tooltip>
            </Button>


            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick('Parking')} style={{minWidth: 'auto', padding: '6px', margin: '0'}}>
                <Tooltip title="Parking">
                    <span>
                        <FaParking/>
                    </span>
                </Tooltip>
            </Button>


            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick('Both')} style={{ position: 'relative', minWidth: 'auto', padding: '16px', margin: '0', lineHeight: '0' }}>
                <Tooltip title="Both Main & Parking">
               <span>
                 <span style={{position: 'absolute', left: '0', top: '0', transform: 'translate(20%, 20%) rotate(90deg)'}}>
                    <FaTicketAlt/>
                 </span>
                  <span style={{position: 'absolute', left: '10%', top: '10%', transform: 'translate(60%, 60%)'}}>
                    <FaParking/>
                  </span>
               </span>
                </Tooltip>
            </Button>

        </div>
    );
};

interface ProductionMappingDetailsFormProps {
    sourceProductionKey: string;
    sourceType: string;
    localDate: string;
    classification: string;
    isMapped: boolean;
    primaryPerformer: string;
    venue: string;
}

interface ActionButtonProps {
    id: number;
    productionMappingDetailsFormProps: ProductionMappingDetailsFormProps;
}

interface MasterProductionFilter {
    master_localDate_gte: string;
    master_localDate_lte: string;
    master_searchText_fts: string;
}

export const MasterProductionChild= (productionMappingDetailsFormProps: ProductionMappingDetailsFormProps) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [filter, setFilter] = useState<MasterProductionFilter>({
        'master_localDate_gte': productionMappingDetailsFormProps.localDate,
        'master_localDate_lte': productionMappingDetailsFormProps.localDate,
        'master_searchText_fts': ` ${productionMappingDetailsFormProps.primaryPerformer} ${productionMappingDetailsFormProps.venue}`
    });
    const fetchData = async (filter: any) => {
        setLoading(true);
        
        const sort = {
            field: 'localDate',
            order: 'DESC'
        };
        const pagination = {
            page: 1,
            perPage: 100
        };
        const respData: any = await dataProvider.getList('master-productions', {filter, sort, pagination});
        setData(respData.data);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchData(filter);
    }, [filter]);
    
    const handleFilterChange = (filterName: string, value: any) => {
        const newFilter = {...filter};
        switch (filterName) {
            case 'master_localDate_gte':
                newFilter['master_localDate_gte'] = value;
                break;
            case 'master_localDate_lte':
                newFilter['master_localDate_lte'] = value;
                break;
            case 'master_searchText_fts':
                newFilter['master_searchText_fts'] = value;
                break;
        }
        setFilter(newFilter);
    }

    const CustomRefreshButton = () => {
        const handleRefreshClick = () => {
            fetchData(filter);
        };

        return (
            <Button label="Refresh" onClick={handleRefreshClick}>
                <RefreshIcon />
            </Button>
        );
    };
    
    const renderFilters = () => {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextField
                    sx={{marginRight: "1rem"}}
                    label="Search text"
                    value={filter.master_searchText_fts}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFilterChange('master_searchText_fts', event.target.value);
                    }}
                />
                <DatePicker
                    sx={{marginRight: "1rem"}}
                    label="Date from"
                    value={parseISO(filter.master_localDate_gte)}
                    onChange={(newValue) => handleFilterChange('master_localDate_gte', format(newValue!, "yyyy-MM-dd"))}
                />
                <DatePicker
                    sx={{marginRight: "1rem"}}
                    label="Date to"
                    value={parseISO(filter.master_localDate_lte)}
                    onChange={(newValue) => handleFilterChange('master_localDate_lte', format(newValue!, "yyyy-MM-dd"))}
                />
            </LocalizationProvider>
        </div>
    );
    }
    

    return (
        <Card sx={{margin: "1rem", padding: "1rem"}}>
            <CustomRefreshButton/>
            {renderFilters()}
            {loading ? <CircularProgress />
                :
                data.length === 0 ? <Typography variant={"h3"} sx={{marginTop: "1rem"}}>No results found</Typography>
                    :
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell align="right">Classification</TableCell>
                            <TableCell align="right">Production Name</TableCell>
                            <TableCell align="right">Production Date</TableCell>
                            <TableCell align="right">Venue</TableCell>
                            <TableCell align="right">Venue Location</TableCell>
                            <TableCell align="right">Master Production Id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <ActionButton id={row.id} productionMappingDetailsFormProps={productionMappingDetailsFormProps} />
                                </TableCell>
                                <TableCell align="right">{row.classification}</TableCell>
                                <TableCell align="right">{row.productionName}</TableCell>
                                <TableCell align="right">{DateTimeDisplay({sourceDate: row.localDate, sourceTime: row.localTime})}</TableCell>
                                <TableCell align="right">{row.venueName}</TableCell>
                                <TableCell align="right">{`${row.venueCity}, ${row.venueState}`}</TableCell>
                                <TableCell align="right">{row.id}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </Card>
    );
};

