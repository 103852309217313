import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

interface VenueLocationFieldProps extends FieldProps {
}

export const VenueLocationField = (props : VenueLocationFieldProps) => {
    const record = useRecordContext(props);

    const location = [record.venueCity, record.venueState].filter(Boolean).join(', ');

    return (
        <div>
            <Typography variant="body2">{location}</Typography>
        </div>
    );
};
