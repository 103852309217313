export enum CustomerMappingStatus {
    Pending = 'Pending',
    Mapped = 'Mapped',
    Rejected = 'Rejected'
}

export enum CustomerMappingAction {
    Approve = 'Approve',
    Reject = 'Reject',    
}
const baseChipStyles={
    fontSize: '0.65rem',    
    padding: '.1rem',
    margin: '0rem',
    borderRadius: '2px',    
    fontWeight: 'bold',
    height:'auto'
}
export const dateChipStyles = {
    ...baseChipStyles, backgroundColor: 'lightblue',minWidth:'180px'
};
export const statusChipStyles = {
    ...baseChipStyles,minWidth:'4.5rem'
};
export const getBackgroundColorForStatus = (status: string) => {
    switch (status) {
        case CustomerMappingStatus.Mapped:
            return '#20c997'
        case CustomerMappingStatus.Rejected:
            return '#ed5e5e'
        default: return '#ffc107'
    }
}