import { FaHistory } from "react-icons/fa";
import { PosOrganizationAuditList } from "../components/Audits/PosOrganizationAuditList";

const PosOrganizationAudits = {
    name: "pos-organization-audits",
    icon: FaHistory,
    list: PosOrganizationAuditList,
    menuLabel: "Pos Organizations",
};

export default PosOrganizationAudits;
