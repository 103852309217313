import { useDataProvider, useNotify, useRecordContext } from "react-admin";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const ProductionMappingDeleteButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleDelete = async () => {
        var data = {
            masterProductionId: record.masterProductionId ? record.masterProductionId : record.id,
            originationSourceType: record.originationSourceTypes.split(",")[0],
            classification: record.classification,
        }
        try {
            await dataProvider.deleteProductionPriorityMapping({ data });
            notify(`Mapping Deleted`, { type: "success" });
        }
        catch (error: any) {
            notify(error.body[0].errorMessage || 'An unknown error occurred', { type: "error" });
        }
    }
    return <IconButton aria-label="delete" onClick={handleDelete}>
        <DeleteIcon color="error" />
    </IconButton>
}
export default ProductionMappingDeleteButton;