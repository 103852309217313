import { FaBuilding } from "react-icons/fa";
import { OrganizationList } from "../components/Organizations/OrganizationList";
import { OrganizationEdit } from "../components/Organizations/OrganizationEdit";
import { OrganizationCreate } from "../components/Organizations/OrganizationCreate";

const Organizations = {
  name: "organizations",
  icon: FaBuilding,
  list: OrganizationList,
  edit: OrganizationEdit,
  create: OrganizationCreate,
  menuLabel: "Organizations",
};

export default Organizations;
