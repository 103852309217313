import React from 'react';
import { List, Datagrid, TextField, EditButton, useRecordContext, DeleteButton, useDataProvider, useNotify, HttpError, useRefresh } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { JsonField } from "react-admin-json-view";

export const NamespaceOverridesList = () => {
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const CustomEdit = () => {
        const navigate = useNavigate();

        const record = useRecordContext();
        return (<EditButton onClick={(event) => {
            event.preventDefault();
            navigate(`/logging/${record.id}`, { state: record, replace: true });
        }} />);
    };
    const CustomDelete = () => {
        const record = useRecordContext();
        return (<DeleteButton onClick={(event) => {
            event.preventDefault();
            dataProvider.deleteOverride(record).then((response: any) => {
                notify(`Delete request sent`, { type: "success" });
                refresh();

            }).catch(async (error: any) => {
                if (error instanceof HttpError) {
                    console.error("Error sending delete request:", error);
                    notify("Error deleting override", { type: "error" });
                }
            })
        }} />);
    };

    return (
        <List>
            <Datagrid>
                <TextField source="serviceKey" label="Service Key" />
                            <JsonField
                                source="namespaces"
                                jsonString={false}
                                reactJsonOptions={{
                                    name: null,
                                    collapsed: true,
                                    enableClipboard: false,
                                    displayDataTypes: false,
                                }}
                            />
                <CustomEdit></CustomEdit>
                <CustomDelete></CustomDelete>
            </Datagrid>
        </List>
    );
};


