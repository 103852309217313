import {
    BooleanField,
    Datagrid,
    EditButton,
    TextField,
    List,
    useRecordContext,
    Button,
    HttpError,
    useDataProvider,
    useNotify,
    useRefresh,
    TopToolbar,
    useListContext
} from "react-admin";
import SyncIcon from "@mui/icons-material/Sync";

const SyncWithStripeButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();

    const handleClick = async () => {
        try {
            await dataProvider.syncBillingAccount({ data: record });
            notify(`Synchronization Successful`, { type: "success" });
            refresh();
        } catch (error) {
            if (error instanceof HttpError) {
                console.error("Error synchronizing account:", error);
                notify("Error synchronizing account", { type: "error" });
            }
        }
    };
    return (
        <Button
            label="Sync"
            onClick={handleClick}
        >
            <SyncIcon />
        </Button>
    );
};

const SyncSelectedButton = () => {
    const { selectedIds } = useListContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const notify = useNotify();
    const handleClick = async () => {
        try {
            await dataProvider.syncManyBillingAccounts({ data: selectedIds });
            notify(`Synchronization Successful`, { type: "success" });
            refresh();
        } catch (error) {
            if (error instanceof HttpError) {
                console.error("Error synchronizing one or more accounts:", error);
                notify("Error synchronizing one or more accounts", { type: "error" });
            }
        }
    };
    return (
        <Button
            label="Sync Selected"
            onClick={handleClick}
        >
            <SyncIcon />
        </Button>
    )
};

const ListActions = () => (
    <TopToolbar>
        <SyncSelectedButton />
    </TopToolbar>
);

export const BillingOrganizationsList = () => (
    <List
        sort={{ field: "organizationKey", order: "ASC" }}>
        <Datagrid bulkActionButtons={<ListActions />}>
            <TextField source="organizationKey" />
            <TextField source="organizationName" />
            <TextField source="status" textAlign="center" />
            <TextField source="organizationExternalId" />
            <TextField source="subscriptionId" />
            <TextField source="subscriptionItemId" />
            <BooleanField source="isBillingActive" textAlign="center" />
            <EditButton />
            <SyncWithStripeButton />
        </Datagrid>
    </List>
);