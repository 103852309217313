import { FaClock } from "react-icons/fa";
import { ScheduleInstanceList } from "../components/ScheduleInstances/ScheduleInstanceList";
import { ScheduleInstanceEdit } from "../components/ScheduleInstances/ScheduleInstanceEdit";




const ScheduleInstances = {
    name: "instances",
    icon: FaClock,
    list: ScheduleInstanceList,
    edit: ScheduleInstanceEdit,
    //create: SettingCreate,
    menuLabel: "Schedule Instance",
};

export default ScheduleInstances;