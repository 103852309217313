import { Edit, SelectInput, SimpleForm, TextInput } from "react-admin";
import { EditToolbar } from "../../CustomToolbar/EditToolbar";
import { logTypeChoices } from "../common";

export const LoggingDefaultEdit = () => {
    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput source="id" label="Id" required disabled />
                <TextInput source="type" label="Service Key" required disabled />
                <SelectInput source="value" label="Log Level" required choices={logTypeChoices} />
            </SimpleForm>
        </Edit>
    )
};