import {
    Datagrid,
    List,
    TextField,
    TextInput,
    BooleanInput,
    FunctionField,
    Link,
    FilterButton,
    ExportButton,
    TopToolbar,
    Pagination,
    useDataProvider,
    DeleteButton,
    useRecordContext
} from "react-admin";

import { useLocation } from 'react-router-dom'
import {useEffect, useState} from "react";

import {Typography} from "@mui/material";
import SectionAutoComplete from "./SectionAutoComplete";
import {CombinedDateTimeField} from "../../utils/CombinedDateTimeField";
import SectionMappingDeleteButton from "./SectionMappingDeleteButton";


interface Venue {
    id: string;
    masterVenueName: string;
    masterVenueCity: string;
    masterVenueState: string;
}
interface LocationState {
    venue: Venue;
}

const sectionFilter = [
    <BooleanInput
        defaultValue={"true"}
        source="needsMapping" label="Needs Mapping"
        parse={(value) => value.toString()}
        format={(value) => value === 'true'}
    />,
    <TextInput source="sectionName" label="Section Name"/>,
];

const IsMappedField = (props: any) => (
    <FunctionField
        {...props}
        render={(record: any) => record[props.source] ? 'No' : 'Yes'} 
    />
);

const DeleteMappingButton = (props: any) => (
    <FunctionField
        {...props}
        render={(record: any) => record[props.source] ? "" : <DeleteButton />}
    />
);
const ProductionExternalLink = () => {
    const record = useRecordContext();
    const url = record.vividSeatsProductionUrl;
    return (
        url ?
            <a href={url} style={{textDecoration: "none"}} target="_blank" rel="noreferrer">VS Production Page</a>
            : null
    );
};

const GoToVenuesButton = () => {
    return (
        <Link
            sx={{ width: "auto" }}
            to={{
                pathname: `/section-mapping-venue`
            }}
            onClick={(event) => event.stopPropagation()}
        >
            Go back to Venues
        </Link>
    );
};

const ListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton/>
        <GoToVenuesButton />
    </TopToolbar>
);


export const SectionMappingList: React.FC = (props) => {
        const [venue, setVenue] = useState<Venue>(  {id: "", masterVenueName: "", masterVenueCity: "", masterVenueState: ""});
        const location = useLocation();
        const state = location.state as LocationState;
        const dataProvider = useDataProvider();
        

        useEffect(() => {
            const getVenue = async () => {
                var venueTemp = state?.venue;
                if (!venueTemp) {
                    const searchParams = new URLSearchParams(location.search);
                    const filterParam = searchParams.get('filter');
                    
                    if (filterParam) {
                        var filters = JSON.parse(filterParam);
                        var masterVenueId = filters["masterVenueId"];
                        var resp = await dataProvider.getOne('venues', {id: masterVenueId});
                        var data = resp.data;
                        venueTemp = {
                            id: data.id,
                            masterVenueName: data.name,
                            masterVenueCity: data.city,
                            masterVenueState: data.state
                        };
                    }
                }
                setVenue(venueTemp);
            }
            getVenue();
        }, []);
        
    return (
        <>
            <Typography variant="h5" sx={{paddingTop: "1rem"}}>{`Section Mapping - ${venue?.masterVenueName}, ${venue?.masterVenueCity}, ${venue?.masterVenueState}`}</Typography>
            <List 
                {...props} 
                filters={sectionFilter} 
                title={`Section Mapping - ${venue?.masterVenueName}`} 
                actions={<ListActions/>} 
                pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
                perPage={25}
                sort={{ field: 'sectionName', order: 'ASC' }}
            >
                <Datagrid bulkActionButtons={false}>
                  <TextField source="id" sortable={false}/>
                  <TextField source="sectionName" label="Origination Section Name" />
                    <SectionAutoComplete venueId={venue?.id} venueName={venue?.masterVenueName} source="masterVenueSection" label="Master Venue Section Name" sortable={false}/>
                <IsMappedField source="needsMapping" label="Mapped" sortable={false}/>
                <ProductionExternalLink />
                <DeleteMappingButton source="needsMapping" label="" sortable={false}/>
                <TextField source="productionName" sortable={false}/>
                <FunctionField source="productionDate" render={ (record: any) => record.productionDate ?  <CombinedDateTimeField sourceDate="productionDate" sourceTime="productionTime" label={"Production Date and Time"} sortable={false}/> : null}/>
               <SectionMappingDeleteButton />
                </Datagrid>
            </List>
        </>
    )
}
;   
