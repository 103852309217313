import React, { useState } from "react";
import { SimpleForm, TextInput, Create, SelectInput } from "react-admin";
import { ValueInputField, configurationValueTypeChoices } from "./common";


export const ConfigurationCreate = () => {
  const [type, setType] = useState("");
  return (
  <Create>
    <SimpleForm>
      <TextInput source="key" required />
      {type && <ValueInputField type={type} />}
      <SelectInput source="type" choices={configurationValueTypeChoices}  onChange={(e) => setType(e.target.value)} required/>
      <TextInput source="description" />
    </SimpleForm>
  </Create>
)};
