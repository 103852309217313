import React, {useEffect, useState} from 'react';
import {Datagrid, EditButton, List, TextField, useDataProvider, useRecordContext} from 'react-admin';
import {StripePrice, StripeProduct} from "../../utils/customDataProvider";
import {Typography} from "@mui/material";


export const ProductNameField = ({productChoices}: { productChoices: StripeProduct[] }) => {
    const record = useRecordContext();
    const productName = productChoices.find(product => product.id === record.productId)?.name || '';
    return <Typography>{productName}</Typography>;
};

export const PriceNameField = ({priceChoices}: { priceChoices: StripePrice[] }) => {
    const record = useRecordContext();
    const priceName = priceChoices.find(price => price.id === record.priceId)?.name || '';
    return <Typography>{priceName}</Typography>;
};

export const StripeConfigurationsList = () => {
    const dataProvider = useDataProvider();
    const [productChoices, setProductChoices] = useState<StripeProduct[]>([]);
    const [priceChoices, setPriceChoices] = useState<StripePrice[]>([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsResponse = await dataProvider.getStripeProducts();
                setProductChoices(productsResponse);
            } catch (error) {
                console.error('Failed to fetch products', error);
            }
        };

        fetchProducts();
    }, [dataProvider]);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const pricesResponse = await dataProvider.getStripePrices('');
                setPriceChoices(pricesResponse);
            } catch (error) {
                console.error('Failed to fetch prices', error);
            }
        };

        fetchPrices();
    }, [dataProvider]);

    return (
        <List sort={{ field: 'configKey', order: 'DESC' }}>
            <Datagrid>
                <TextField source="configKey" />
                <ProductNameField productChoices={productChoices} />
                <PriceNameField priceChoices={priceChoices} />
                <TextField source="defaultOrganizationType" />
                <EditButton />
            </Datagrid>
        </List>
    );
};
