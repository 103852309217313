import React, { useState, useEffect } from "react";
import { useRecordContext, useNotify, Button, Confirm, SelectInput } from "react-admin";
import axios from "axios";
import { buttonStyle, resourceUrls } from "../../../utils/constants";
import { useMsal } from "@azure/msal-react";
import { extractToken } from "../../../utils/general";
import { CircularProgress } from "@mui/material";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Entry } from "./common";
import { MarketplaceSourceTypes, MarketplaceSourceTypesChoices} from "../common";

type CredentialsFormProps = {
  marketplace: Entry;
  onDone: () => void;
};
const CredentialsForm: React.FC<CredentialsFormProps> = (props) => {
  const { instance, accounts } = useMsal();
  const marketplace = props.marketplace;
  const { id, name, abbreviation, validDate, sourceType } = marketplace;

  const record = useRecordContext(props);
  const notify = useNotify();
  const [credentialsExist, setCredentialsExist] = useState<boolean>(false);
  const [credentialsValid, setCredentialsValid] = useState<boolean>(false);
  const [, setCredentialsValidDate] = useState<string|undefined>(validDate);
  const [sourceTypeValue, setSourceTypeValue] = useState<string|undefined>(sourceType);
  const [accountId, setAccountId] = useState<string>("");
  const [apiToken, setApiToken] = useState<string>("");
  const [apiSecret, setApiSecret] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>(name);
  const [abbreviationValue, setAbbreviationValue] =
    useState<string>(abbreviation);
  const [token, setToken] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeletion] = useState<boolean>(false);
  const [getCredentialsLoading, setGetCredentialsLoading] =
    useState<boolean>(false);
  const [credentialsRetrieved, setCredentialsRetrieved] =
    useState<boolean>(false);
  const [openDeleteConfirm, setDeleteConfirmOpen] = useState(false);
  const [marketplaceId, setMarketplaceId] = useState<number|undefined>(id);

  useEffect(
    () => {
      const account = accounts[0];
      extractToken(account, instance).then((resp) => {
        setToken(resp);
      });
      setSourceTypeValue(sourceType);
     
    }, // eslint-disable-next-line
    []
  );

  const logout = () => {
    instance.logoutRedirect().catch((e) => {
      console.log(e);
    });
  };

  const getCredentials = async () => {
    setGetCredentialsLoading(true);
    let resp = null;
    try {
      const response = await axios.get(
        `${resourceUrls.MarketplaceService}/marketplacecredential/${record.organizationKey}/${marketplaceId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCredentialsExist(response.data.isStored);
      setCredentialsValid(response.data.isValid);
      setCredentialsValidDate(response.data.validDate);
      setCredentialsRetrieved(true);
      resp = response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
      setCredentialsRetrieved(false);
    }
    setGetCredentialsLoading(false);
    return resp;
  };

  const handleSave = async (testConnectionOnly: boolean = false) => {
    setSaving(true);
    setCredentialsRetrieved(false);
    try {
      //test crenedtials
      let resp = await postCredentials(true);

      if (resp.data?.isValid) {
        if (!testConnectionOnly) {
            resp = await postCredentials(false);
          }

        if (resp.data.id) {
          setMarketplaceId(resp.data.id);
        }
        
        notify(testConnectionOnly ?  "Credentials Valid" :"Credentials successfully saved", {
          type: "success",
          undoable: false,
        });
      }
      else {
        notify("Credentials invalid", {
          type: "error",
          undoable: false,
        });
      }
      
      setAccountId("");
      setApiToken("");
    } catch (error: any) {
      console.log(error);
      notify(`Error saving credentials`, { type: "error", undoable: false });
      if (error.response.status === 401) {
        logout();
      }
    }
    setSaving(false);
  };

  const postCredentials = (testConnectionOnly: boolean) => {

    var data: any = {
      sourceType: sourceTypeValue,
      name: nameValue,
      abbreviation: abbreviationValue,
      testConnectionOnly
    };

    if (sourceTypeValue === MarketplaceSourceTypes.VsSkybox) {
      data.vsSkybox = {
        accountId: parseInt(accountId),
        apiToken,
        apiSecret
      };
      }
    if (sourceTypeValue === MarketplaceSourceTypes.TicketEvolution) { 
      data.ticketEvolution = {
        officeId: parseInt(accountId),
        apiKey: apiToken,
        apiSecret
      }
    }
    
    return marketplaceId ? axios.put(
      `${resourceUrls.MarketplaceService}/marketplacecredential/${record.organizationKey}/${marketplaceId}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    ) : axios.post(
      `${resourceUrls.MarketplaceService}/marketplacecredential/${record.organizationKey}`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }


  const handleTestConnection = async () => {
    setGetCredentialsLoading(true);
    try {
      const response = await getCredentials();
      if (response.isValid) {
        notify("Credentials valid", {
          type: "success",
          undoable: false,
        });
      }
      else {
        notify("Credentials invalid", {
          type: "error",
          undoable: false,
        });
      }
    } catch (error) {
      console.log(error);
      notify(`Error getting credentials`, { type: "error", undoable: false });
    }
    setGetCredentialsLoading(false);
  };

  const handleDelete = async () => {
    setDeletion(true);
    try {
      await axios.delete(
        `${resourceUrls.MarketplaceService}/marketplacecredential/${record.organizationKey}/${marketplaceId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      props.onDone();
    } catch (error: any) {
      console.log(error);
      notify(`Error deleting credentials`, { type: "error", undoable: false });
      if (error.response.status === 401) {
        logout();
      }
    }
    setDeletion(false);
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirm = () => setDeleteConfirmOpen(true);
  const handleDeleteDialogClose = () => setDeleteConfirmOpen(false);
  const credentialsEntered = accountId && apiToken && apiSecret && sourceTypeValue && nameValue && abbreviationValue;

  return (
    <div>
      {name && <h4>{`${name} (${abbreviation})`}</h4>}

      <Button
        label={credentialsEntered ? "Test Entered Credentials" : "Test Existing Credentials"}
        variant="contained"
         onClick={(e) => credentialsEntered ? handleSave(true) : handleTestConnection()}
        sx={buttonStyle}
      />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {credentialsRetrieved ? (
          <ul>
            <li>
              <span>Credentials stored: </span>
              {credentialsExist ? (
                <Check style={{ color: "darkgreen" }} />
              ) : (
                <Close style={{ color: "darkred" }} />
              )}
            </li>
            <li>
              <span>Credentials valid: </span>
              {credentialsValid ? (
                <Check style={{ color: "darkgreen" }} />
              ) : (
                <Close style={{ color: "darkred" }} />
              )}
            </li>
          </ul>
        ) : (
          <></>
        )}
        {getCredentialsLoading && <CircularProgress />}
        <SelectInput
          source="sourceType"
          value={sourceTypeValue}
          choices={MarketplaceSourceTypesChoices}
          onChange={(e) => setSourceTypeValue(e.target.value)}
          required
        />
        <TextField
          type="string"
          value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}
          label="Name"
        />
        <TextField
          type="string"
          value={abbreviationValue}
          onChange={(e) => setAbbreviationValue(e.target.value)}
          label="Abbreviation"
        />
        <TextField
          type="number"
          value={accountId}
          onChange={(e) => setAccountId(e.target.value)}
          label={sourceTypeValue === MarketplaceSourceTypes.VsSkybox ? "Account Id" : "Office Id"}
        />
        <TextField
          type="password"
          value={apiToken}
          onChange={(e) => setApiToken(e.target.value)}
          label={sourceTypeValue === MarketplaceSourceTypes.VsSkybox ? "API Token" : "API Key"}
        />
         <TextField
          type="password"
          value={apiSecret}
          onChange={(e) => setApiSecret(e.target.value)}
          label="API Secret"
        />
        {saving ? (
          <CircularProgress />
        ) : (
          <Button
            label="Save"
            variant="contained"
            onClick={(e) => handleSave()}
            sx={{ marginTop: "1rem", width: "10rem" }}
            disabled={
              accountId && apiToken && nameValue && apiSecret && sourceTypeValue
                ? false
                : true
            }
          />
        )}
        {deleting ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              label="Delete"
              variant="contained"
              onClick={handleDeleteConfirm}
              sx={{ marginTop: "1rem", width: "10rem" }}
              disabled={
                marketplaceId
                  ? false
                  : true
              }
            />
            <Confirm
              isOpen={openDeleteConfirm}
              loading={deleting}
              title="Delete Confirmation"
              content="Are you sure you want to delete this credential set?"
              onConfirm={handleDelete}
              onClose={handleDeleteDialogClose}
            />
          </>
        )}
        <Button
          label="Cancel"
          variant="contained"
          size="small"
          sx={{ marginTop: "1rem", width: "10rem" }}
          onClick={() => props.onDone()}
        />
      </div>
    </div>
  );
};

export default CredentialsForm;
