import React, { useState, useEffect } from "react";
import { useRecordContext, useNotify, Button, Confirm, SelectInput, SimpleForm, RadioButtonGroupInput } from "react-admin";
import axios from "axios";
import { buttonStyle, resourceUrls } from "../../../utils/constants";
import { useMsal } from "@azure/msal-react";
import { extractToken } from "../../../utils/general";
import { CircularProgress, Checkbox, FormControlLabel } from "@mui/material";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import { Entry } from "./common";
import Webhooks from "./Webhooks";

type CredentialsFormProps = {
  pos: Entry;
  onDone: () => void;
};

type PosSelections = {
  name: string,
  id: string
}

const posChoices: PosSelections[] = [{ name: 'Select PoS', id: '0' }, { name: 'SkyBox', id: 'VSSkybox' }, { name: 'Ticket Network Direct', id: 'TicketNetworkDirect' }];

const CredentialsForm: React.FC<CredentialsFormProps> = (props) => {

  const tNDPosId = 'TicketNetworkDirect';
  const Pos11Type = 'POS11';
  const PosNextType = 'POSNext';
  const skyboxId = 'VSSkybox';

  const { instance, accounts } = useMsal();
  const pos = props.pos;
  const { posOrganizationId, name, abbreviation, isDebug } = pos;

  const isNew = pos.abbreviation === '' && pos.name === '' && pos.isDebug === false;
  const posData = posChoices.find(x => x.id === pos.sourceType) || { name: 'Select PoS', id: '0' };

  const record = useRecordContext(props);
  const notify = useNotify();
  const [credentialsExist, setCredentialsExist] = useState<boolean>(false);
  const [credentialsValid, setCredentialsValid] = useState<boolean>(false);
  const [credentialsEnabled, setCredentialsEnabled] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>("");
  const [apiToken, setApiToken] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>(name);
  const [posValue, setPosValue] = useState<string>(posData.id);
  const [tndPosType, setTndPosType] = useState<string>(!isNew && !pos.isPos11 ? PosNextType : Pos11Type);
  const [abbreviationValue, setAbbreviationValue] =
    useState<string>(abbreviation);
  const [token, setToken] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeletion] = useState<boolean>(false);
  const [getCredentialsLoading, setGetCredentialsLoading] =
    useState<boolean>(false);
  const [credentialsRetrieved, setCredentialsRetrieved] =
    useState<boolean>(false);
  const [openDeleteConfirm, setDeleteConfirmOpen] = useState(false);
  const [isDebugValue, setIsDebug] = useState(isDebug);
  const [posOrgId, setPosOrgId] = useState<number | undefined>(undefined);

  useEffect(
    () => {
      const account = accounts[0];
      extractToken(account, instance).then((resp) => {
        setToken(resp);
      });
      setPosOrgId(posOrganizationId);
    }, // eslint-disable-next-line
    []
  );

  const logout = () => {
    instance.logoutRedirect().catch((e) => {
      console.log(e);
    });
  };


  const getCredentials = async (posOrganizationId: any) => {
    setGetCredentialsLoading(true);
    let resp = null;
    try {
      const response = await axios.get(
        `${resourceUrls.PosIntegrationService}/poscredential/${record.organizationKey}/${posOrganizationId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      showCredentialsStatus(response);

      resp = response.data;
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
      setCredentialsRetrieved(false);
    }
    setGetCredentialsLoading(false);
    return resp;
  };

  const calculateIsPos11 = () => {
    return posValue === tNDPosId && tndPosType === Pos11Type;
  }

  const calculateIsSkybox = () => {
    return posValue === skyboxId;
  }

  const showCredentialsStatus = (resp: any) => {
    setCredentialsExist(resp.data.isStored);
    setCredentialsValid(resp.data.isValid);
    setCredentialsEnabled(resp.data.isEnabled);
    setCredentialsRetrieved(true);
  }

  const handleSave = async (testConnectionOnly: boolean = false) => {
    setSaving(true);
    setCredentialsRetrieved(false);
    try {
      //test credentials
      let resp = await postCredentials(true);

      if (resp.data?.isPosValid) {
        if (!testConnectionOnly) {
          resp = await postCredentials(false);
        }

        if (resp.data.posOrganizationId) {
          setPosOrgId(resp.data.posOrganizationId);
          await getCredentials(resp.data.posOrganizationId);
        }
        
        
        notify(testConnectionOnly ? "Credentials Valid" : "Credentials valid and successfully saved", {
          type: "success",
          undoable: false,
        });
      }
      else {
        notify("Credentials invalid", {
          type: "error",
          undoable: false,
        });
      }

      setAccountId("");
      setApiToken("");
      
    } catch (error: any) {
      console.log(error);
      notify(`Error saving credentials`, { type: "error", undoable: false });
      if (error.response.status === 401) {
        logout();
      }
    }
    setSaving(false);
  };

  const handleMetadataSave = async () => {
    setSaving(true);
    try {
      await postMetadata();
    } catch (error: any) {
      console.log(error);
      notify(`Error saving metadata`, { type: "error", undoable: false });
      if (error.response.status === 401) {
        logout();
      }
    }
    setSaving(false);
  };

  const postCredentials = (testConnectionOnly: boolean) => {
    return axios.post(
      `${resourceUrls.PosIntegrationService}/poscredential`,
      {
        accountId: parseInt(accountId),
        apiToken: apiToken,
        organizationKey: record.organizationKey,
        posOrganizationId,
        isEnabled: true,
        name: nameValue,
        abbreviation: abbreviationValue,
        isDebug: isDebugValue,
        sourceType: posValue,
        isPos11: calculateIsPos11(),
        testConnectionOnly
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

  const postMetadata = () => {
    return axios.post(
      `${resourceUrls.PosIntegrationService}/poscredential`,
      {
        organizationKey: record.organizationKey,
        posOrganizationId,
        name: nameValue,
        abbreviation: abbreviationValue,
        isDebug: isDebugValue,
        sourceType: posValue,
        isPos11: calculateIsPos11(),
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }


  const handleTestConnection = async () => {
    setGetCredentialsLoading(true);
    try {
      const response = await getCredentials(posOrgId);
      if (response.isValid) {
        notify("Credentials valid", {
          type: "success",
          undoable: false,
        });
      }
      else {
        notify("Credentials invalid", {
          type: "error",
          undoable: false,
        });
      }
    } catch (error) {
      console.log(error);
      notify(`Error getting credentials`, { type: "error", undoable: false });
    }
    setGetCredentialsLoading(false);
  };

  const handleDelete = async () => {
    setDeletion(true);
    try {
      await axios.delete(
        `${resourceUrls.PosIntegrationService}/poscredential/${record.organizationKey}/${posOrgId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      props.onDone();
    } catch (error: any) {
      console.log(error);
      notify(`Error deleting credentials`, { type: "error", undoable: false });
      if (error.response.status === 401) {
        logout();
      }
    }
    setDeletion(false);
    setDeleteConfirmOpen(false);
  };

  const handlePosSelection = (evt: any) => {
    setPosValue(evt.target.value);
    if (evt.target.value === tNDPosId) {
      let posType = "";
      if (isNew || pos.isPos11) {
        posType = Pos11Type;
      }
      else {
        posType = PosNextType;
      }
      setTndPosType(posType);
    }
  }
  const handleDeleteConfirm = () => setDeleteConfirmOpen(true);
  const handleDeleteDialogClose = () => setDeleteConfirmOpen(false);
  

  return (
    <div>
      {name && <h4>{`${name} (${abbreviation})`}</h4>}

      <Button
        label={"Test Existing Credentials"}
        variant="contained"
        onClick={(e) => handleTestConnection()}
        sx={buttonStyle}
        disabled={isNew && !posOrgId}
      />

      <div style={{ display: "flex", flexDirection: "column" }}>
        {credentialsRetrieved ? (
          <ul>
            <li>
              <span>Credentials stored: </span>
              {credentialsExist ? (
                <Check style={{ color: "darkgreen" }} />
              ) : (
                <Close style={{ color: "darkred" }} />
              )}
            </li>
            <li>
              <span>Credentials enabled: </span>
              {credentialsEnabled ? (
                <Check style={{ color: "darkgreen" }} />
              ) : (
                <Close style={{ color: "darkred" }} />
              )}
            </li>
            <li>
              <span>Credentials valid: </span>
              {credentialsValid ? (
                <Check style={{ color: "darkgreen" }} />
              ) : (
                <Close style={{ color: "darkred" }} />
              )}
            </li>
          </ul>
        ) : (
          <></>
        )}
        {getCredentialsLoading && <CircularProgress />}
        <SimpleForm toolbar={false} onSubmit={e => { return }}>
          {posValue.length > 0 &&
            <SelectInput
              source='sourceType'
              choices={posChoices}
              onChange={handlePosSelection}
              value={posValue}
              defaultValue={posValue}
              disabled={!isNew}
              required
              label="Point of Sale"
            />}
          {posValue === tNDPosId &&
            <RadioButtonGroupInput
              label={tndPosType != PosNextType ? false : 'Coming in 2025'}
              defaultValue={tndPosType}
              source="PosType"
              onChange={e => setTndPosType(e.target.value)}
              choices={[
                { id: Pos11Type, name: Pos11Type },
                { id: PosNextType, name: PosNextType },

              ]} />}
          <TextField
            type="string"
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            label="Name"
          />
          <TextField
            type="string"
            value={abbreviationValue}
            onChange={(e) => setAbbreviationValue(e.target.value)}
            label="Abbreviation"
          />
          <TextField
            type="number"
            value={accountId}
            onChange={(e) => setAccountId(e.target.value)}
            label={posValue === tNDPosId ? "Broker Id" : "Account ID"}
          />
          <TextField
            type="password"
            value={apiToken}
            onChange={(e) => setApiToken(e.target.value)}
            label={posValue === tNDPosId ? "Broker Token" : "API Token"}
          />
          <FormControlLabel control={<Checkbox checked={isDebugValue}
            onChange={(e) => setIsDebug(e.target.checked)} />} label="IsDebug" />
          {saving ? (
            <CircularProgress />
          ) : (
            <Button
              label="Save All"
              variant="contained"
              onClick={(e) => handleSave()}
              sx={{ marginTop: "1rem", width: "15rem" }}
              disabled={
                accountId && apiToken && nameValue &&
                  (posValue != tNDPosId || posValue === tNDPosId && tndPosType != PosNextType)
                  ? false
                  : true
              }
            />
          )}
          {saving ? (
            <CircularProgress />
          ) : (
            <Button
              label="Save Metadata Only"
              variant="contained"
              onClick={(e) => handleMetadataSave()}
              sx={{ marginTop: "1rem", width: "15rem" }}
              disabled={posValue === tNDPosId && tndPosType === 'POSNext'}
            />
          )}
          {deleting ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                label="Delete"
                variant="contained"
                onClick={handleDeleteConfirm}
                sx={{ marginTop: "1rem", width: "15rem" }}
                disabled={
                  posOrgId
                    ? false
                    : true
                }
              />
              <Confirm
                isOpen={openDeleteConfirm}
                loading={deleting}
                title="Delete Confirmation"
                content="Are you sure you want to delete this credential set?"
                onConfirm={handleDelete}
                onClose={handleDeleteDialogClose}
              />
            </>
          )}
          <Button
            label="Cancel"
            variant="contained"
            size="small"
            sx={{ marginTop: "1rem", width: "15rem" }}
            onClick={() => {
              setPosValue('0');
              props.onDone()
            }}
          />
        </SimpleForm>
        {
          calculateIsSkybox() && <Webhooks pos={pos} /> 
        }
      </div>

    </div>

  );
};

export default CredentialsForm;
