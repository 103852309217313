import { FaHistory } from "react-icons/fa";
import { OrganizationAuditList } from "../components/Audits/OrganizationAuditList";

const OrganizationAudits = {
    name: "organization-audits",
    icon: FaHistory,
    list: OrganizationAuditList,
    menuLabel: "Organizations",
};

export default OrganizationAudits;
