import { FaHistory } from "react-icons/fa";
import { ProductionAssignmentAuditList } from "../components/Audits/ProductionAssignmentAuditList";

const ProductionAssignmentAudits = {
  name: "production-assignment-audits",
  icon: FaHistory,
  list: ProductionAssignmentAuditList,
  menuLabel: "Production Assigments",
};

export default ProductionAssignmentAudits;
