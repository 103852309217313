import React from 'react';
import {FieldProps, useRecordContext} from 'react-admin';
import {Typography} from '@mui/material';
import {format, parseISO} from "date-fns";

interface CombinedDatesProps extends FieldProps {
    firstInput: string;
    secondInput: string;
}


const DateTimeDisplay = (dateString : string) => {
   try {
       return dateString ? format(parseISO(dateString), "EEE•d MMM yyyy h:mm a") : 'N/A';
   }
   catch (e) {
       return 'N/A';
   }
};


export const CombinedDatesField = ({firstInput, secondInput, label}: CombinedDatesProps) => {
    const record = useRecordContext();
    if (!record) return null;

    const firstDate = DateTimeDisplay(record[firstInput]);
    const secondDate = DateTimeDisplay(record[secondInput]);

    return (
        <div>
            <Typography variant="body2" style={{ fontSize: '0.75rem' }}>{firstDate}</Typography>
            <Typography variant="body2">
                <span style={{
                    fontSize: '0.65rem',
                    backgroundColor: 'lightblue',
                    padding: '0.1rem 0.15rem',
                    borderRadius: '4px',
                }}>
                    {secondDate}
                </span>
            </Typography>
        </div>
    );
};


