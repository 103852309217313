import { FaTicketAlt } from "react-icons/fa";
import { InventoryList } from "../components/Inventory/InventoryList";

const Inventory = {
  name: "pos-inventory",
  icon: FaTicketAlt,
  list: InventoryList,
  menuLabel: "Org. Inventory",
};

export default Inventory;
