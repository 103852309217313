import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  ReferenceField,
  SelectArrayInput,
  FunctionField,
  ReferenceArrayInput,
  useRecordContext,
  ExportButton,
  FilterButton,
  TopToolbar,
  Button,
  useDataProvider,
  useRefresh,
  useNotify,
  HttpError,
  ArrayField,
  SingleFieldList,
  BooleanInput,
  BooleanField
} from "react-admin";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import Chip from "@mui/material/Chip";
import { MemberStatuses} from "../../utils/constants";
import {externalTenantTypeChoices, memberStatusChoices} from "./common";

const RoleChip: React.FC = () => {
  const record = useRecordContext().toString();
  return <Chip label={record} size="small" />;
};

const defaultFilterValue = [
  MemberStatuses.Active,
  MemberStatuses.Pending,
  MemberStatuses.Inactive,
];

const memberFilter = [
  <TextInput label="Name" source="name" />,
  <TextInput label="Email" source="email" />,
  <TextInput label="Phone" source="phone" />,
  <ReferenceArrayInput
    label="Organization"
    source="organizationId"
    reference="organization-details"
    perPage={99999}
  >
    <SelectArrayInput
      key="organizationId"
      source="organizationId"
      optionText={(record: { name: any; knownAs: any; id: any }) =>
        `${record.name} [${record.knownAs} - ${record.id}]`
      }
    />
  </ReferenceArrayInput>,
  <SelectArrayInput
    label="Status"
    source="status_eq"
    choices={memberStatusChoices}
    defaultValue={defaultFilterValue}
  />,
  <SelectArrayInput
      label="External Tenant Type"
      source="externalTenantType"
      choices={externalTenantTypeChoices}
  />,
  <BooleanInput source="isSupport" label="Is Support" defaultValue={true} />
];

const CanNotSendSendResetPassword = (status: string): boolean => {
  return status !== MemberStatuses.Active;
};
const SendResetPassword = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    try {
      await dataProvider.sendResettPassword({ data: record });
      notify(`Password reset sent`, { type: "success" });
      refresh();
    } catch (error) {
      if (error instanceof HttpError) {
        console.error("Error sending password reset:", error);
        notify("Error sending password reset", { type: "error" });
      }
    }
  };
  return (
    <Button
      label="Send Reset Password"
      onClick={handleClick}
      disabled={CanNotSendSendResetPassword(record.status)}
    >
      <DoneIcon />
    </Button>
  );
};

const ViewOrg = () => {
  const record = useRecordContext();
  return (
    <Link
      to={{
        pathname: `/organizations/` + record.organizationId,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      View Org
    </Link>
  );
};

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

export const MemberList = () => (
  <List
    filters={memberFilter}
    actions={<ListActions />}
    filterDefaultValues={{ status_eq: defaultFilterValue }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="objectId" label="AAD ID" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="username" />
      <TextField source="phone" />
      <ReferenceField
        source="organizationId"
        reference="organization-details"
        label="Organization"
        link={(record) =>
          `?filter=${JSON.stringify({
            organizationId: [record.organizationId],
          })}`
        }
      >
        <TextField source="name" />
        {" ["}
        <TextField source="knownAs" />
        {" - "}
        <TextField source="id" />
        {"]"}
      </ReferenceField>
      <ArrayField source="roleIdNames" label="Roles">
        <SingleFieldList linkType={false}>
          <RoleChip />
        </SingleFieldList>
      </ArrayField>
      <TextField source="phone" />
      <TextField source="status" />
      <BooleanField source="isSupport" label="Is Support" />
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedOn: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedOn),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <SendResetPassword />
      <ViewOrg />
      <EditButton />
    </Datagrid>
  </List>
);
