import { authConstants } from "./utils/constants";

export const msalConfig = {
  auth: {
    clientId: authConstants.ClientId!,
    authority: `https://${authConstants.TenantName}.b2clogin.com/${authConstants.TenantName}.onmicrosoft.com/${authConstants.PolicyId}`,
    knownAuthorities: [`https://${authConstants.TenantName}.b2clogin.com`],
    //

    postLogoutRedirectUri: authConstants.LogoutUrl!,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["openid"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
