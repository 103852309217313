import {
  Datagrid,
  List,
  TextField,
} from "react-admin";

export const PermissionList = () => (
  <List perPage={50}>
    <Datagrid isRowSelectable={()=>false} stickyHeader={true} >
      <TextField source="value" />
      <TextField source="name" />  
      <TextField source="description" />                
    </Datagrid>
  </List>
);
