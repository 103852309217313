import { FaMap } from "react-icons/fa";
import { ProductionMappingsEdit } from "../components/ProductionMappings/ProductionMappingsEdit";
import {SourceProductionMappingList} from "../components/SourceProductionMappings/SourceProductionMappingList";

const SourceProductionMappings = {
    name: "source-production-mappings",
    icon: FaMap, 
    list: SourceProductionMappingList,
    edit: ProductionMappingsEdit,
    menuLabel: "Production Mapping Review",
};

export default SourceProductionMappings;
