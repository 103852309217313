import { FaHistory } from "react-icons/fa";
import { ClientUserAuditList } from "../components/Audits/ClientUserAuditList";

const ClientUserAudits = {
    name: "client-user-audits",
    icon: FaHistory,
    list: ClientUserAuditList,
    menuLabel: "Client Users",
};

export default ClientUserAudits;
