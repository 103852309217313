import {
    Datagrid,
    List,
    TextField,
    TextInput, useRecordContext, Link, Pagination
} from "react-admin";

const ViewSections = () => {
  const record = useRecordContext();
  return (
      <Link
          to={{
            pathname: `/section-mapping-section`,
            search: `filter=${JSON.stringify({
              masterVenueId: record.id.toString(),
                needsMapping: "true"
            })}`

          }}
          onClick={(event) => event.stopPropagation()}
          state={{ venueName: record.masterVenueName, venueId: record.id, venue: record}}
      >
        View Sections
      </Link>
  );
};

const venueFilter = [
  <TextInput source="name"  label="Venue Name"/>,
];

export const VenueSectionMappingList = () => (
  <List 
      filters={venueFilter} 
      title="Section Mapping - Venues"
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      perPage={25}
      sort={{ field: 'unmappedSectionCount', order: 'DESC' }}
  >
    
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="masterVenueName" label="Venue" />
      <TextField source="masterVenueCity" label="City" />
      <TextField source="masterVenueState" label="State" />
      <TextField source="unmappedSectionCount" label="Unmapped Sections" />
      <ViewSections />        
    </Datagrid>
  </List>
);
