import {
  AutocompleteInput,
  Datagrid,
  DateTimeInput,
  EditButton,
  FunctionField,
  List,
  ReferenceInput,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from "react-admin";
import { FC } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { formatDateWithoutTZ, parseDateToUTC, prodFilterToQuery, venueFilterToQuery } from "../../utils/helpers";
import OrganizationLinkField from "../Common/OrganizationLinkField";


const productionAssignmentFilter: any = [
  <ReferenceInput source="productionId" reference="productions">
  <AutocompleteInput optionText={choice => `${choice.id} - ${choice.productionName}`} filterToQuery={prodFilterToQuery}/>
</ReferenceInput>,
<ReferenceInput source="assignedUsername" reference="members" label="User">
  <AutocompleteInput optionText={choice => `${choice.name} - ${choice.username}`} optionValue="username" filterToQuery={venueFilterToQuery} label="User"/>
</ReferenceInput>,
<DateTimeInput
    label="Created Date from"
    source="createdDate_gte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Created Date to"
    source="createdDate_lte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,

];

interface ProductionFieldProps {
  source?: string;
}

const ProductionField: FC<ProductionFieldProps> = ({source}) => {
  const record = useRecordContext();
  return record  ? (
    <Link to={`/productions/?filter=${JSON.stringify({
      id: [record.productionId],
    })}`}>{`${record.productionId}-${record.productionName}`}</Link>
  ) : null;
};

export const ProductionAssignmentList = () => {


  return (
    <List filters={productionAssignmentFilter} sort={{ field: 'productionId', order: 'ASC' }}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceManyField
        source="username"
        target="username"
        reference="members"
        label="Assigned user"
      >
        <SingleFieldList>
            <TextField
              source="name"
              label="User"
              sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
            />
          
        </SingleFieldList>
      </ReferenceManyField>
          <ReferenceManyField
              source="organizationKey"
              target="key"
              reference="organization-details"
              perPage={99999}
              label="Organization"
          >
              <SingleFieldList>
                  <OrganizationLinkField
                      source="name"
                      label="Organization"
                      sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
                  />
              </SingleFieldList>
          </ReferenceManyField>
        <ProductionField source="production"/>
        <TextField source="venueName" label="Venue"/>
        <FunctionField
        label="Created Date"
        render={(record: { createdDate: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.createdDate),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
        <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedDate: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedDate),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
        <EditButton />
      </Datagrid>
    </List>
  );
};
