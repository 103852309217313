import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';

interface FormattedPricingCriteriaFieldProps extends FieldProps {
}
const styles = {
    fontSize: '0.55rem',
    backgroundColor: 'lightblue',
    padding: '0',
    margin: '0.05rem',
    borderRadius: '2px',
    width:'90px',
    fontWeight: 'bold'
};
export const FormattedPricingCriteriaField = (props: FormattedPricingCriteriaFieldProps) => {
    const record = useRecordContext(props);

    const offsetDisplay = `${record.ruleSet.incrementMethod}${record.ruleSet.amount}`;
    const groupDisplay = `${record.ruleSet.offsetIncrementMethod}${record.ruleSet.offsetAmount}`;
    const shareOptionsType = record.ruleSet?.shareOptionsType ? (record.ruleSet?.shareOptionsType as string).toUpperCase() : null

    const offset = 'OFFSET ' + ((record.ruleSet.amount || 0) === 0 ? 'MATCH' : record.ruleSet.increment === 'decrease' ? `-${offsetDisplay}` : `+${offsetDisplay}`);
    const group = 'GROUP ' + ((record.ruleSet.offsetAmount || 0) === 0 ? 'MATCH' : record.ruleSet.offsetIncrement === 'decrease' ? `-${groupDisplay}` : `+${groupDisplay}`);
    return (
        <>
            <div style={{width:'190px'}}>
                <Chip style={styles} size='small' label={offset} variant="outlined"></Chip>
                <Chip style={styles} size='small' label={group} variant="outlined"></Chip>
            

                <Chip style={styles} size='small' label={`FLOOR $${record.floorPrice || ''}`} variant="outlined" />

                <Chip style={styles} size='small' label={`CEILING $${record.ceilingPrice || ''}`} variant="outlined"></Chip>
                {shareOptionsType &&
                    <Chip style={styles} size='small' label={shareOptionsType} variant="filled"></Chip>}

            </div>
        </>
    );
};