import { FaTools } from "react-icons/fa";
import { ConfigurationCreate } from "../components/Configurations/ConfigurationCreate";
import { ConfigurationEdit } from "../components/Configurations/ConfigurationEdit";
import { ConfigurationList } from "../components/Configurations/ConfigurationList";

const Configurations = {
  name: "configurations",
  icon: FaTools,
  list: ConfigurationList,
  edit: ConfigurationEdit,
  create: ConfigurationCreate,
  menuLabel: "Configurations",
};

export default Configurations;
