import { FaHistory } from "react-icons/fa";
import { ResetPasswordAuditList } from "../components/Audits/ResetPasswordAuditList";

const ResetPasswordAudits = {
  name: "reset-password-audits",
  icon: FaHistory,
  list: ResetPasswordAuditList,
  menuLabel: "Reset Passwords",
};

export default ResetPasswordAudits;
