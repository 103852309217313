import { FaBuilding } from "react-icons/fa";
import { BillingOrganizationsList } from "../components/BillingOrganizations/BillingOrganizationList";
import { BillingOrganizationEdit } from "../components/BillingOrganizations/BillingOrganizationEdit";

const BillingOrganizations = {
    name: "billing-organizations",
    icon: FaBuilding,
    list: BillingOrganizationsList,
    edit: BillingOrganizationEdit,
    menuLabel: "Billing Organizations"
};

export default BillingOrganizations;