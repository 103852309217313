import { FaHistory } from "react-icons/fa";
import { PricingTemplateAuditList } from "../components/Audits/PricingTemplateAuditList";

const PricingTemplateAudits = {
  name: "pricing-template-audits",
  icon: FaHistory,
  list: PricingTemplateAuditList,
  menuLabel: "Pricing Templates",
};

export default PricingTemplateAudits;
