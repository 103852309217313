import {
  Datagrid,
  List,
  TextField,
  SelectArrayInput,
  FunctionField,
  DateField,
  DateTimeInput,
  TextInput,
} from "react-admin";
import { JsonField } from "react-admin-json-view";
import { isJsonString } from "../../utils/general";
import { formatDateWithoutTZ, parseDateToUTC } from "../../utils/helpers";
import { deliveryMethodChoices, notificationStatusChoices } from "./common";
import { NotificationStatuses } from "../../utils/constants";

const notificationsFilter = [
  <SelectArrayInput
    source="status"
    choices={notificationStatusChoices}
    defaultValue={[
      NotificationStatuses.Delivered,
      NotificationStatuses.Canceled,
    ]}
  />,
  <DateTimeInput
    label="Created Date from"
    source="createdDate_gte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Created Date to"
    source="createdDate_lte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Last Delivery Date from"
    source="lastDeliveryDateTime_gte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Last Delivery Date to"
    source="lastDeliveryDateTime_lte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <SelectArrayInput
    label="Delivery Method"
    source="deliveryMethod_eq"
    choices={deliveryMethodChoices}
  />,
  <TextInput source="to" />,
  <TextInput source="subject" />,
];
export const NotificationsList = () => (
  <List filters={notificationsFilter} sort={{ field: "id", order: "DESC" }}>
    <Datagrid>
      <TextField source="id" />
      <FunctionField
        label="Message"
        render={(record: any) => {
          return isJsonString(record.message) ? (
            <JsonField
              source="message"
              jsonString={true}
              reactJsonOptions={{
                // Props passed to react-json-view
                name: null,
                collapsed: true,
                enableClipboard: false,
                displayDataTypes: false,
              }}
            />
          ) : (
            <TextField source="message" />
          );
        }}
      />

      <TextField source="deliveryMethod" label="Delivery Method" />
      <TextField source="deliveryResponse" label="Delivery Response" />
      
      <JsonField
        source="target"
        jsonString={false}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
         
      <TextField source="status" label="Status" />
      <DateField
        source="createdDate"
        label="Created Date"
        showTime
        options={{ timeZone: "UTC", timeZoneName: "short" }}
      />
      <DateField
        source="lastDeliveryDateTime"
        label="Last Delivery Date"
        showTime
        options={{ timeZone: "UTC", timeZoneName: "short" }}
      />
    </Datagrid>
  </List>
);
