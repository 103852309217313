import { FaKey } from "react-icons/fa";
import { PermissionList } from "../components/Permissions/PermissionList";

const Permissions = {
  name: "permissions/org-permissions",
  icon: FaKey,
  list: PermissionList,  
  menuLabel: "Permissions",
};

export default Permissions;
