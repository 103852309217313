import React, { useCallback, useEffect, useState } from 'react';
import {
    List,
    TextField,
    ListProps,
    DateInput,
    SearchInput,
    SelectInput, FunctionField,
    required,
    TopToolbar,
    ExportButton,
    useDataProvider,
    useRecordContext, useListContext
} from "react-admin";
import { ThemeProvider } from '@mui/material/styles';

import { MasterProductionPagination } from './ProductionPagination';
import { VenueLocationField } from './VenueLocationField';
import { CombinedDateTimeField } from '../../utils/CombinedDateTimeField';
import { MasterProduction } from "./MasterProduction";
import { Identifier } from "ra-core";
import { CombinedDatesField } from "../../utils/CombinedDatesField";
import { format, addYears } from "date-fns";
import { PriorityGrid } from "./PriorityGrid";
import { MappingTheme } from "./MappingTheme";

import ProductionMappingDeleteButton from "./ProductionMappingDeleteButton";
import { Production } from "./Production";
import { MasterProductionChild } from "./MasterProductionChild";
import { SourceProductionChild } from "./SourceProductionChild";
import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { CrowdSourcedRecordView } from './CrowdSourcedRecordView';
import CrowdSourceMappingRejectButton from './CrowdSourceMappingRejectButton';
import { CustomerMappingStatus } from '../Mapping/constants';

const sxFilters = { width: '13rem' };
type ExpandRowProps = {
    id: Identifier;
    record: Production;
    resource: string;
};



export const ProductionMappingsList = (props: ListProps<MasterProduction>) => (
    <ThemeProvider theme={MappingTheme}>
        <ProductionMappingsListClean {...props} />
    </ThemeProvider>
);

export const ProductionMappingsListClean = (props: ListProps<MasterProduction>) => {
    const [entries, setEntries] = useState<any[]>([]);
    const [isCrowdSource, setIsCrowdSource] = useState<boolean>(false);
    const dataProvider = useDataProvider();
    const [open, setOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = React.useState({} as any);
    
    const defaultFilterValues = {
        production_localDate_gte: format(new Date(), 'yyyy-MM-dd'),
        production_localDate_lte: format(addYears(new Date(), 1), 'yyyy-MM-dd'),
        production_productionStatus: 'Active',
        production_sourceType: 'Master',
        mapping_status: 'Pending',
        production_isPriority: 'true'
    };
    const ViewAudits = () => {
        const record = useRecordContext();
        const handleAuditClick = () => {

            setSelectedRecord(record);
            setOpen(true);
        };
        return (
            <Button onClick={() => handleAuditClick()}>View Audits</Button>
        );
    };

    const ResetFilters = () => {
        const {
            setFilters,
            displayedFilters
        } = useListContext();
        const handleResetFiltersClick = () => {
            setFilters(defaultFilterValues, displayedFilters);
        };
        return (
            <Button onClick={() => handleResetFiltersClick()}>Reset Filters</Button>
        );
    };

    const MainListActions = () => (
        <TopToolbar>
            <ResetFilters />
            <ExportButton />
        </TopToolbar>
    )

    const productionMappingsFilter = [
        <SearchInput
            resettable
            source="production_searchText_fts"
            sx={sxFilters}
            alwaysOn />,
        <DateInput
            label="From"
            sx={sxFilters}
            source="production_localDate_gte"
            alwaysOn />,
        <DateInput
            label="To"
            sx={sxFilters}
            source="production_localDate_lte"
            alwaysOn />,
        <SelectInput
            sx={sxFilters}
            source="production_productionStatus"
            label="Production Status"
            alwaysOn
            choices={[
                { id: 'Active', name: 'Active' },
                { id: 'Cancelled', name: 'Cancelled' }
            ]} />,
        <SelectInput
            sx={sxFilters}
            source="production_sourceType"
            validate={required()}
            alwaysOn
            label="Mapping Type"
            onChange={e => { setIsCrowdSource(e.target.value === 'CrowdSource') }}
            value={'Master'}
            choices={[
                { id: 'Master', name: 'Master' },
                { id: 'Source', name: 'Source' },
                { id: 'CrowdSource', name: 'CrowdSource' }
            ]} />,
        <SelectInput
            sx={sxFilters}
            source="production_classification"
            alwaysOn
            label="Classification"
            choices={[
                { id: 'Main', name: 'Main' },
                { id: 'Parking', name: 'Parking' }
            ]} />,
        <SelectInput sx={sxFilters}
            hidden={!isCrowdSource}
            label="Organization"
            alwaysOn
            source="mapping_organizationKey"
            emptyText={"Select an Organization"}
            key="organizationKey"
            optionValue="organizationKey"
            hiddenLabel={false}
            resettable
            choices={entries}
            optionText={(record: { name: any; knownAs: any; id: any }) =>
                `${record.name} [${record.knownAs ?? ''} - ${record.id}]`
            } />,

            <SelectInput
            sx={sxFilters}
            hidden={!isCrowdSource}
            source="mapping_status"            
            alwaysOn
            label="Crowd Source Status"
            validate={required()}
            value={'Pending'}
            choices={[
                { id: CustomerMappingStatus.Pending, name: CustomerMappingStatus.Pending },
                { id: CustomerMappingStatus.Rejected, name: CustomerMappingStatus.Rejected },
                { id: CustomerMappingStatus.Mapped, name: CustomerMappingStatus.Mapped }
            ]} />,
    ];
    const getEntries = useCallback(() => {
        dataProvider
            .getOrganizations()
            .then((data: any) => {
                setEntries(data)
            });
    }, [dataProvider]);

    useEffect(() => {
        getEntries();
    }, [getEntries]);

    useEffect(() => {
        const mappingTypeElement = document.querySelector("div.MuiInputBase-root input[name='production_sourceType']");
        if (mappingTypeElement) {
            const value = mappingTypeElement.getAttribute('value');
            if (value && value==='CrowdSource' && !isCrowdSource) {
                setIsCrowdSource(true);
            }
        }
    }, []);

    const getOrganizationName = (orgKey: string) => {
        if (!entries) return '';
        else return entries.find(x => x.organizationKey === orgKey);
    }
    const expandRow = ({ id, record }: ExpandRowProps) => {
        return <ThemeProvider theme={MappingTheme}>
            {
                record.mappingType === 'Master' || record.mappingType === 'CrowdSource' ?
                    <SourceProductionChild
                        masterProductionId={record.mappingType === 'CrowdSource' ? record.masterProductionId! : record.id}
                        localDate={record.localDate}
                        classification={record.classification}
                        isMapped={record.isMapped}
                        sourceProductionKey={record.sourceKey}
                        sourceType={record.sourceType}
                        isCrowdSource={record.mappingType === 'CrowdSource'}
                        primaryPerformer={record.primaryPerformer ?? ''}
                        venue={record.venueName ?? ''}
                        crowdSourceId={id.toString()}
                    />
                    :
                    <MasterProductionChild
                        sourceProductionKey={record.sourceKey}
                        sourceType={record.sourceType}
                        localDate={record.localDate}
                        primaryPerformer={record.primaryPerformer ?? ''}
                        venue={record.venueName ?? ''}
                        classification={record.classification}
                        isMapped={record.isMapped}
                    />
            }
        </ThemeProvider>;
    };

    return (
        <>
            <List title="Priority Production"
                actions={<MainListActions />}
                filters={productionMappingsFilter}
                filterDefaultValues={defaultFilterValues}
                sort={{ field: 'localDate', order: 'ASC' }}
                resource={'production-mappings'}
                perPage={50}
                pagination={<MasterProductionPagination />}
                hasCreate={false} hasEdit={false}
                hasList={false} hasShow={false}
            >
                <PriorityGrid
                    expandSingle={true}
                    expand={expandRow}
                    bulkActionButtons={false}
                    optimized
                >
                    <TextField source="id" sortable={false} />
                    <FunctionField source="productionName"
                        sortable={false}
                        label="Production Name"
                        render={(record: { productionName: string, mappingType: string, masterProductionId: any }) => {
                            return record.mappingType === 'CrowdSource' ? `${record.productionName}-[${record.masterProductionId}]` : record.productionName
                        }
                        }
                    />
                    <CombinedDateTimeField source="localDate" sourceDate="localDate" sourceTime="localTime" label={"Venue Date and Time"} sortable={true} />
                    <TextField source="primaryPerformer" sortable={false} />
                    <TextField source="venueName" label="Venue" sortable={false} />
                    <VenueLocationField label="Venue Location" sortable={false} />
                    <TextField source="classification" />
                    <TextField source="productionStatus" sortable={false} />
                    <FunctionField source="sourceType"
                        label="Source Type"
                        render={(record: any) => record.mappingType == 'Source' ? record.sourceType : "PRQX"} />
                    <TextField source="mappingType" sortable={false} />
                    <TextField source="sourceKey" sortable={false} />
                    <CombinedDatesField firstInput="lastModifiedDate" secondInput="createdDate"
                        label={"Updated Date and Time / Created Date and Time"} sortable={false} />
                    {isCrowdSource &&
                        <FunctionField source="organizationKey"
                            sortable={false}
                            label="Organization"
                            render={(record: { organizationKey: any }) => {
                                const orgName = getOrganizationName(record.organizationKey)?.name;
                                return orgName ? orgName : record.organizationKey;
                            }
                            }
                        />}
                    <FunctionField
                        sortable={false}
                        label=""
                        render={(record: { mappingType: any }) => {
                            if (record.mappingType == 'CrowdSource') {
                                return <CrowdSourceMappingRejectButton />
                            }
                            return <ProductionMappingDeleteButton />
                        }
                        }
                    />

                    <FunctionField source="organizationKey"
                        sortable={false}
                        label=""
                        render={(record: { mappingType: any }) => {
                            if (record.mappingType == 'CrowdSource') {
                                return <ViewAudits></ViewAudits>
                            }
                            return <></>
                        }
                        }
                    />

                </PriorityGrid>
            </List>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth='xl' PaperProps={{
                sx: { width: "100%" },
            }}>
                <CrowdSourcedRecordView record={selectedRecord}></CrowdSourcedRecordView>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} autoFocus variant='outlined'>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
