import { FaTheaterMasks } from "react-icons/fa";
import { ProductionList } from "../components/Productions/ProductionList";

const Productions = {
  name: "productions",
  icon: FaTheaterMasks,
  list: ProductionList,
  menuLabel: "Productions",
};

export default Productions;
