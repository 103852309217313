import {ApplicationInsights, DistributedTracingModes, ITelemetryItem} from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { telemetryConfig } from "./utils/constants";

var reactPlugin = new ReactPlugin();

var telemetryInitializer = (envelope : ITelemetryItem) => {
  // @ts-ignore
  envelope.tags["ai.cloud.role"] = "admin-app";
}
var appInsights = new ApplicationInsights({
  config: {
    connectionString: telemetryConfig.appInsightsKey,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    distributedTracingMode: DistributedTracingModes.W3C,
    enableDebug: true,
    loggingLevelConsole: 2,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [
      reactPlugin
    ],
  },
});
appInsights.addTelemetryInitializer(telemetryInitializer);

export { reactPlugin, appInsights };
