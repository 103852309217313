import { FaHospital } from "react-icons/fa";
import { OrganizationRequestEdit } from "../components/OrganizationRequests/OrganizationRequestEdit";
import { OrganizationRequestList } from "../components/OrganizationRequests/OrganizationRequestList";

const OrganizationRequests = {
  name: "organization-requests",
  icon: FaHospital,
  list: OrganizationRequestList,
  edit: OrganizationRequestEdit,
  menuLabel: "Organization Requests",
};

export default OrganizationRequests;
