import { FaHistory } from "react-icons/fa";
import { OrganizationPricingDefaultsAuditList } from "../components/Audits/OrganizationPricingDefaultsAuditList";

const OrganizationPricingDefaultsAudits = {
  name: "organization-pricing-defaults-audits",
  icon: FaHistory,
  list: OrganizationPricingDefaultsAuditList,
  menuLabel: "Org Pricing Defaults",
};

export default OrganizationPricingDefaultsAudits;
