import { FaMap } from "react-icons/fa";
import {VenueMappingsList} from "../components/VenueMappings/VenueMappingsList";

const VenueMappings = {
    name: "source-venues",
    icon: FaMap, 
    list: VenueMappingsList,
    menuLabel: "Venue Mappings",
};

export default VenueMappings;
