import React from 'react';
import { FieldProps, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

interface FormattedVenueFieldProps extends FieldProps {
}

export const FormattedVenueField = (props : FormattedVenueFieldProps) => {
    const record = useRecordContext(props);

    const location = [record.venueCity, record.venueState].filter(Boolean).join(', ');

    return (
        <div style={{display:'inline-block'}}>
            <Typography variant="body2" sx={{fontSize:'.85rem', width:'8rem'}}>{record.venueName}</Typography>
            <Typography variant="body2" sx={{fontSize:'.75rem', width:'8rem'}}>{location}</Typography>
        </div>
    );
};