import {useDataProvider} from "react-admin";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React, {useEffect} from "react";
import TableBody from "@mui/material/TableBody";
import {CircularProgress, Typography} from "@mui/material";
import {DateTimeDisplay} from "../../utils/CombinedDateTimeField";

export const SourceProductionMappingAudits = (recordContainer:any) => {
    
    
    const dataProvider = useDataProvider();
    const [audits, setAudits] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const record = recordContainer.record;  
    const sourceProduction = record.sourceProduction;
    const {productionName, localDate, localTime, venueName, venueCity, venueState} = sourceProduction;
    const sourceProductionLine1 = `[${record.sourceProductionKey}, ${record.sourceType}, ${record.classification}]`;
    const sourceProductionLine2 = `${productionName} | ${DateTimeDisplay({ sourceDate: localDate, sourceTime: localTime })} | ${venueName} - ${venueCity}, ${venueState}`;
    useEffect(() => {
        console.log("record", record)
        const fetchAudits = async () => {
            const data = {
                SourceProductionKey: record.sourceProductionKey,
                SourceType: record.sourceType,
                Classification: record.classification,
            }
          var resp =  await dataProvider.getSourceProductionMappingAudits(data);
          setAudits(resp);
          setLoading(false);
        }
        fetchAudits();
        
    }, []);
    
    return (

        <Card sx={{margin: "0.2rem", padding: "0.5rem"}}>
            <Typography variant={"h6"} sx={{margin: "0.5rem"}}>Audits</Typography>
            <Typography variant={"body1"} sx={{margin: "0.5rem"}}>{sourceProductionLine1}</Typography>
            <Typography variant={"body1"} sx={{margin: "0.5rem"}}>{sourceProductionLine2}</Typography>
            {loading ? <CircularProgress />
            :
                <>
                    {audits && audits.length > 0 ?
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Master Production ID</TableCell>
                                    <TableCell align="left">Master Production Name</TableCell>
                                    <TableCell align="left">Master Production Location</TableCell>
                                    <TableCell align="left">Master Production Date</TableCell>
                                    <TableCell align="left">Mapped By</TableCell>
                                    <TableCell align="left">Mapping Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {audits.map((audit: any) => (
                                    <TableRow key={audit.id}>
                                        <TableCell align="left">{audit.masterProductionId}</TableCell>
                                        <TableCell align="left">{audit.masterProduction.productionName}</TableCell>
                                        <TableCell align="left">{`${audit.masterProduction.masterVenue.name} ${audit.masterProduction.masterVenue.city}, ${audit.masterProduction.masterVenue.state}`}</TableCell>
                                        <TableCell align="left">{`${audit.masterProduction.localDate} ${audit.masterProduction.localTime}`}</TableCell>
                                        <TableCell align="left">{audit.createdBy}</TableCell>
                                        <TableCell align="left">{audit.createdDate}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        :
                        <Typography variant={"body1"} sx={{margin: "1rem"}}>No Audits found</Typography>
                    }
                </>
            }
        </Card>
    );
};