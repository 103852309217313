import React, { useState, useEffect } from "react";
import { useRecordContext, useNotify, Button, Confirm} from "react-admin";
import axios from "axios";
import { buttonStyle, resourceUrls } from "../../../utils/constants";
import { useMsal } from "@azure/msal-react";
import { extractToken } from "../../../utils/general";
import { CircularProgress, Checkbox, FormControlLabel } from "@mui/material";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";

import { Entry } from "./common";

type CredentialsFormProps = {
  pos: Entry;
};

type Webhook = {
  name: string;
  isSubscribed: boolean;
};

const actionTypes = {
    subscribe: "subscribe",
    unsubscribe: "unsubscribe",
    };


const Webhooks: React.FC<CredentialsFormProps> = (props) => {
  
  const { instance, accounts } = useMsal();
  const pos = props.pos;
  const { posOrganizationId } = pos;

  const record = useRecordContext(props);
  const notify = useNotify();
  const [token, setToken] = useState<string>("");
  const [getWebhooksLoading, setGetWebhooksLoading] =
    useState<boolean>(false);
  
  const [unsubscribeOpen, setUnsubscribeOpen] = useState(false);
  const [posOrgId, setPosOrgId] = useState<number | undefined>(undefined);
  const [webhooks, setWebhooks] = useState([]);

  useEffect(
    () => {
      const account = accounts[0];
      extractToken(account, instance).then((resp) => {
        setToken(resp);
      });
      setPosOrgId(posOrganizationId);
    }, // eslint-disable-next-line
    []
  );

    useEffect(() => {
        getWebhooks()
    }, [token]);

  const logout = () => {
    instance.logoutRedirect().catch((e) => {
      console.log(e);
    });
  };


  const getWebhooks = async () => {
    if (!token || !posOrganizationId) return;
    setGetWebhooksLoading(true);
    let resp = null;
    try {
      const response = await axios.get(
        `${resourceUrls.PosIntegrationService}/webhooks?organizationKey=${record.organizationKey}&posId=${posOrgId}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      resp = response.data;
      setWebhooks(resp);
    } catch (error: any) {
      console.log(error);
      if (error.response.status === 401) {
        logout();
      }
    }
    setGetWebhooksLoading(false);
  };
  

  const handleSubscribeUnsubscribe = async (action: string) => {
    if (!action) return;  
    
    try {
      
      let resp = await subscribeUnsubscribe(action);
        if (resp.status === 200) {
          await getWebhooks();
            notify(`Webhooks successfully ${action}ed`, {
                type: "success",
                undoable: false,
            });
        }
    } catch (error: any) {
      console.log(error);
      notify(`Webhooks - error ${action}ing`, { type: "error", undoable: false });
      if (error.response.status === 401) {
        logout();
      }
    }
    if (action === actionTypes.unsubscribe) {
      handleUnsubscribeDialogClose();
    }
  };
  

  const subscribeUnsubscribe = (action: string) => {
    return axios.post(
      `${resourceUrls.PosIntegrationService}/webhooks/${action}`,
      {
        organizationKey: record.organizationKey,
        posId: posOrganizationId,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  }

 
  const handleUnsubscribeConfirm = () => setUnsubscribeOpen(true);
  const handleUnsubscribeDialogClose = () => setUnsubscribeOpen(false);
  
  const isSubscribed = () => { 
    return webhooks.some((webhook: Webhook) => webhook.isSubscribed);
  }

  return (
    <div>
      <h4>Webhooks</h4>

        {getWebhooksLoading ? (
            <CircularProgress />
        ) : (
            <div>
            {webhooks.map((webhook: Webhook) => (
                <div key={webhook.name}>
                <FormControlLabel
                    control={
                    <Checkbox
                        icon={<Close />}
                        checkedIcon={<Check />}
                        checked={webhook.isSubscribed}
                        disabled
                    />
                    }
                    label={webhook.name}
                />
                </div>
            ))}
            </div>
        )}
      <Button
        label={isSubscribed() ? "Unsubscribe" : "Subscribe"}
        variant="contained"
        onClick={(e) => isSubscribed() ? handleUnsubscribeConfirm() : handleSubscribeUnsubscribe(actionTypes.subscribe)}
        sx={buttonStyle}
      />
        
              <Confirm
                isOpen={unsubscribeOpen}
                title="Unsubscribe Confirmation"
                content="Are you sure you want to unsubscribe from the webhooks?"
                onConfirm={() => handleSubscribeUnsubscribe(actionTypes.unsubscribe)}
                onClose={handleUnsubscribeDialogClose}
              />
    </div>
  );
};

export default Webhooks;
