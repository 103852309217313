import { FaHistory } from "react-icons/fa";
import { PosCredentialAuditList } from "../components/Audits/PosCredentialAuditList";

const PosCredentialAudits = {
    name: "pos-credential-audits",
    icon: FaHistory,
    list: PosCredentialAuditList,
    menuLabel: "Pos Credentials",
};

export default PosCredentialAudits;
