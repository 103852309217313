import {
  Datagrid,
  List,
  TextField,
  TextInput,
  SelectArrayInput,
  ReferenceArrayInput,
  ReferenceField,
  FunctionField,
  useRecordContext,
  Link,
  useDataProvider,
  useRefresh,
  Button,
  HttpError,
  useNotify, Confirm,
} from "react-admin";
import { format } from "date-fns";
import {
  ERROR_USER_ALREADY_INVITED,
  ERROR_SENDING_INVITATION,
} from "../../utils/errors";

import DoneIcon from "@mui/icons-material/Done";
import { InvitationStatuses } from "../../utils/constants";
import { invitationStatusChoices } from "./common";
import { lowerCaseCompare } from "../../utils/helpers";
import {useState} from "react";

const ViewOrg = () => {
  const record = useRecordContext();
  return (
    <Link
      to={{
        pathname: `/organizations/` + record.organizationId,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      View Org
    </Link>
  );
};

const defaultFilterValue = [
  InvitationStatuses.Redeemed,
  InvitationStatuses.Pending,
];
const InvitationFilter = [
  <TextInput label="Name" source="displayName" />,
  <TextInput label="Email" source="email" />,
  <TextInput label="Phone" source="phone" />,
  <ReferenceArrayInput
    label="Organization"
    source="organizationId"
     reference="organization-details"
    perPage={99999}
  >
    <SelectArrayInput
      key="organizationId"
      source="organizationId"
      optionText={(record: { name: any; knownAs: any; id: any }) =>
        `${record.name} [${record.knownAs} - ${record.id}]`
      }
    />
  </ReferenceArrayInput>,
  <SelectArrayInput
    label="Status"
    source="status_eq"
    choices={invitationStatusChoices}
    defaultValue={defaultFilterValue}
  />,
];

const CanNotSendInvitation = (status: string): boolean => {
  return (
    lowerCaseCompare(status, InvitationStatuses.Redeemed) ||
    lowerCaseCompare(status, InvitationStatuses.Approved) ||
    lowerCaseCompare(status, InvitationStatuses.Deleted)
  );
};

const ResendInvitation = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    try {
      await dataProvider.resendInvitation({ data: record });
      notify(`Invitation Sent`, { type: "success" });
      refresh();
    } catch (error) {
      if (error instanceof HttpError) {
        console.error("Error sending Invitation:", error);
        const errorMessage = JSON.parse(error.body);
        if (
          errorMessage &&
          errorMessage.message &&
          errorMessage.startsWith("23505")
        )
          notify(ERROR_USER_ALREADY_INVITED, { type: "error" });
        else notify(ERROR_SENDING_INVITATION, { type: "error" });
      }
    }
  };

  return (
    <Button
      label="Resend Invitation"
      onClick={handleClick}
      disabled={CanNotSendInvitation(record.status)}
    >
      <DoneIcon />
    </Button>
  );
};

const CancelInvitation = () => {
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    try {
      await dataProvider.delete("invitations", { id: record.id });
      notify(`Invitation Cancelled`, { type: "success" });
      refresh();
    } catch (error) {
      if (error instanceof HttpError) {
        console.error("Error Cancelling Invitation:", error);
        const errorMessage = JSON.parse(error.body);
        if (
            errorMessage &&
            errorMessage.message &&
            errorMessage.startsWith("23505")
        )
          notify(ERROR_USER_ALREADY_INVITED, { type: "error" });
        else notify(ERROR_SENDING_INVITATION, { type: "error" });
      }
    }
    setOpen(false);
  };

  return (
      <>
          <Button
              label="Cancel Invitation"
              onClick={handleClick}
              disabled={CanNotSendInvitation(record.status)}
          >
            <DoneIcon />
          </Button>
          <Confirm
              isOpen={open}
              title={`Cancel invitation for email ${record && record.email}`}
              content="Are you sure you want to cancel this invitation?"
              onConfirm={handleConfirm}
              onClose={handleDialogClose}
              cancel="Abort"
          />
      </>
  );
};

export const InvitationList = () => (
  <List
    filters={InvitationFilter}
    filterDefaultValues={{ status_eq: defaultFilterValue }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="displayName" label="Full Name" />
      <TextField source="email" />
      <TextField source="phone" />
      <ReferenceField
        source="organizationId"
        reference="organization-details"
        label="Organization"
        link={(record) =>
          `?filter=${JSON.stringify({
            organizationId: [record.organizationId],
          })}`
        }
      >
        <TextField source="name" />
        {" ["}
        <TextField source="knownAs" />
        {" - "}
        <TextField source="id" />
        {"]"}
      </ReferenceField>
      <FunctionField
        label="Expiration"
        render={(record: { expirationDate: any }) =>
          `${format(
            new Date(record.expirationDate),
            "MMMM do yyyy, h:mm:ss a"
          )}`
        }
      />
      <TextField source="status" />
      <TextField source="defaultUserRole" />
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedOn: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedOn),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <ResendInvitation />
      <CancelInvitation />
      <ViewOrg />
    </Datagrid>
  </List>
);
