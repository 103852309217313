import { FaLandmark } from "react-icons/fa";
import { VenueList } from "../components/Venues/VenueList";

const Venues = {
  name: "venues",
  icon: FaLandmark,
  list: VenueList,
  menuLabel: "Venues",
};

export default Venues;
