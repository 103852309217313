import { Edit, SelectInput, SimpleForm, TextInput } from "react-admin";
import { EditToolbar } from "../CustomToolbar/EditToolbar";
import { ValueInputField, configurationValueTypeChoices } from "./common";
import { useState } from "react";


export const ConfigurationEdit = () => {
  
  const [type, setType] = useState("");
  

  return (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="key" disabled />
      <ValueInputField type={type} />
      <SelectInput source="type" choices={configurationValueTypeChoices}  onChange={(e) => setType(e.target.value)} required/>
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
)};
