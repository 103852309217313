import { FaFileInvoiceDollar } from "react-icons/fa";
import { ProductionAssignmentList } from "../components/ProductionAssignments/ProductionAssignmentList";
import { ProductionAssignmentEdit } from "../components/ProductionAssignments/ProductionAssignmentEdit";

const ProductionAssignments = {
  name: "production-assignments",
  icon: FaFileInvoiceDollar,
  list: ProductionAssignmentList,
  edit: ProductionAssignmentEdit,
  menuLabel: "Production Assignments",
};

export default ProductionAssignments;
