import { useEffect, useState } from "react";
import { useDataProvider, useListContext } from "react-admin";
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import { DateTimeDisplay } from "../../utils/CombinedDateTimeField";
//import Card from "@mui/material/Card";
import { CircularProgress, Typography } from "@mui/material";
import { FormattedVenueField } from "./FormattedVenueField";
import { Link } from "react-router-dom";

interface TemplateProductionProps {
    templateId: number | string
}
const styles = {
    headerRow: { backgroundColor: '#E8E8E8', padding: '.2rem', height: '1rem' },
    productionDateHeader: { cursor: 'pointer', backgroundColor: '#E8E8E8', padding: '.2rem', height: '1rem' }
}
const ProductionField = (item: any) => {

    return item?.row ? (
        <Link to={`/productions/?filter=${JSON.stringify({
            id: [item.row.productionId],
        })}`}>{item.row.productionName}</Link>
    ) : null;
};
export const TemplateProductionsExpandedRow = (props: TemplateProductionProps) => {

    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [textFilter, setTextFilter] = useState('');
    
    const list = useListContext();

    
    const fetchData = async () => {
        setLoading(true);

        const range = [0, 499];
        
        if (list.filterValues.organizationKey) {
            const filter= { templateId: props.templateId, organizationKey: list.filterValues.organizationKey };
            const respData: any = await dataProvider.getTemplateProductions({filter , sort: {}, range });
            setData(respData.data);
        }
        setLoading(false);
    }
    useEffect(() => {
        fetchData();
    }, [props.templateId]);



    return (data?.length === 0 ? <Typography variant={"subtitle2"} sx={{ marginTop: "1rem" }}>No results found</Typography> :
        <>
            <TextField placeholder="Search" onChange={e => setTextFilter(e.target.value)} />

            <Typography variant={"subtitle2"} sx={{ marginTop: "1rem" }}>{`Productions found: ${data.length}`}</Typography>

            <TableContainer sx={{ maxHeight: '32rem', overflowY: 'scroll',paddingBottom:'1.5rem' }} >
                <Table aria-label="simple table" stickyHeader >
                    <TableHead sx={{ height: '1rem' }}>
                        <TableRow sx={{ backgroundColor: '#E8E8E8', height: '1rem' }}>
                            <TableCell sx={styles.headerRow} variant="head" align="center">Id</TableCell>
                            <TableCell sx={{ width: 180, backgroundColor: '#E8E8E8', padding: '.2rem' }} align="center">Production Name</TableCell>
                            <TableCell sx={styles.productionDateHeader} variant="head" align="center"> Production Date</TableCell>
                            <TableCell sx={styles.headerRow} variant="head" align="center">Venue</TableCell>
                            <TableCell sx={styles.headerRow} variant="head" align="center" >Production Status</TableCell>
                            <TableCell sx={styles.headerRow} variant="head" align="center">Org Listings</TableCell>
                            <TableCell sx={styles.headerRow} variant="head" align="center">Market Listings </TableCell>
                            <TableCell sx={styles.headerRow} variant="head" align="center">Org Sold </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {loading ? <CircularProgress /> : data && data.length > 0 && data?.filter(r => r.productionName.toLowerCase().includes(textFilter?.toLowerCase()) || r.venueName.toLowerCase().includes(textFilter?.toLowerCase()))
                            .map((row: any) => {

                                return <TableRow
                                    key={row.productionId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}            >

                                    <TableCell align="center">{row.productionId}</TableCell>
                                    <TableCell align="center"><ProductionField row={row}></ProductionField></TableCell>
                                    <TableCell align="center">{DateTimeDisplay({ sourceDate: row.productionDate, sourceTime: row.productionLocalTime })}</TableCell>
                                    <TableCell align="center"><FormattedVenueField record={{ ...row }} /></TableCell>
                                    <TableCell align="center">{row.productionStatus}</TableCell>
                                    <TableCell align="center">{row.myListings}</TableCell>
                                    <TableCell align="center">{row.marketList}</TableCell>
                                    <TableCell align="center">{row.mySoldTickets}</TableCell>
                                </TableRow>

                            })}
                    </TableBody>

                </Table>
            </TableContainer></>)
}