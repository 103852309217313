import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  FunctionField,
  DateField,
  DateInput,
} from "react-admin";
import { format } from "date-fns";

const activeMarketEventsFilter = [
  <TextInput label="Event" source="name" />,
  <TextInput label="Venue" source="venueName" />,
  <DateInput label="Date" source="date" />,
];

export const ActiveMarketEventList = () => (
  <List filters={activeMarketEventsFilter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" label="Event" />
      <FunctionField
        label="Venue"
        render={(record: { venueName: any; venueCity: any; venueState: any }) =>
          `${record.venueName}, ${record.venueCity}, ${record.venueState}`
        }
      />
      <DateField source="date" />
      <DateField source="localTime" showTime showDate={false} />
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedDate: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedDate),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
    </Datagrid>
  </List>
);
