import { FaSkiing } from "react-icons/fa";
import { PerformerList } from "../components/Performers/PerformerList";

const Performers = {
  name: "performers",
  icon: FaSkiing,
  list: PerformerList,
  menuLabel: "Performers",
};

export default Performers;
