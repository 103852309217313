//import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

const cellSx = {
    lineHeight: '1rem !important',
    fontSize: '12px'
}

export const CrowdSourcedRecordView = (recordContainer: any) => {

    return (<Table sx={{ minWidth: 650, lineHeight: '1rem !important' }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#dee2e6', lineHeight: '1rem !important' }} >
            <TableRow sx={{ backgroundColor: '#dee2e6', lineHeight: '1rem !important' }}>
                <TableCell sx={cellSx} align="left">Master Production ID</TableCell >
                <TableCell sx={cellSx} align="left">Source Production Key</TableCell>
                <TableCell sx={cellSx} align="left">Source Type</TableCell>
                <TableCell sx={cellSx} align="left">Master Production Name</TableCell>
                <TableCell sx={cellSx} align="left">Master Production Location</TableCell>
                <TableCell sx={cellSx} align="left">Master Production Date</TableCell>
                <TableCell sx={cellSx} align="left">Status</TableCell>
                <TableCell sx={cellSx} align="left">Mapped By</TableCell>
                <TableCell sx={cellSx} align="left">Mapping Date</TableCell>
                <TableCell sx={cellSx} align="left">Created By</TableCell>
                <TableCell sx={cellSx} align="left">Created Date</TableCell>
                <TableCell sx={cellSx} align="left">Updated By</TableCell>
                <TableCell sx={cellSx} align="left">Updated Date</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {recordContainer &&
                <TableRow key={recordContainer.record.id}>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.masterProductionId}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.sourceKey}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.sourceType}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.productionName}</TableCell>
                    <TableCell sx={cellSx} align="left">{`${recordContainer.record.venueName} ${recordContainer.record.venueCity}, ${recordContainer.record.venueState}`}</TableCell>
                    <TableCell sx={cellSx} align="left">{`${recordContainer.record.localDate} ${recordContainer.record.localTime}`}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.crowdSourceMappingStatus}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.crowdSourceMappedBy}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.crowdSourceMappedDate ? new Date(recordContainer.record.crowdSourceMappedDate).toISOString() : ''}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.crowdSourceCreatedBy}</TableCell>
                    <TableCell sx={cellSx} align="left">{new Date(recordContainer.record.crowdSourceCreatedDate).toISOString()}</TableCell>
                    <TableCell sx={cellSx} align="left">{recordContainer.record.crowdSourceLastModifiedBy}</TableCell>
                    <TableCell sx={cellSx} align="left">{new Date(recordContainer.record.crowdSourceLastModifiedDate).toISOString()}</TableCell>
                </TableRow>
            }
        </TableBody>
    </Table >)
}