import { Create, HttpError, SaveButton, SelectInput, SimpleForm, TextInput, UpdateParams, useDataProvider, useNotify, SimpleFormIterator, ArrayInput } from "react-admin"
import { logTypeChoices, OverrideTypes, validateNamespace } from "./common"
import Button from "@mui/material/Button";
import Cancel from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

export const NamespaceOverrideCreate = (props: any) => {

    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const [entries, setEntries] = useState<OverrideTypes[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getEntries = () => {
            setLoading(true);
            dataProvider
                .getLoggingServiceKeys()
                .then((data: any) => {
                    setEntries(data.json.map((option: string) => ({ id: option, name: option })));
                    setLoading(false);
                });
        };

        getEntries();
    }, [dataProvider]);

    const goToListTable = () => {
        navigate('/logging');
    }

    const handleCreate = (event: any) => {
        var valid = true;
        event.namespaces.forEach((namespaceItem: any) => {
            if (!validateNamespace(namespaceItem.namespace)) {
                notify("Invalid Namespace format", { type: "error" });
                valid = false;
            }
            if (!namespaceItem.logLevel || namespaceItem.logLevel === '') {
                notify("Log level must not be empty", { type: "error" });
                valid = false;
            }
        });
        if (!valid) {
            return;
        }

        const params = {
            id: event.id,
            data: {
                id: event.serviceKey + ':Logging:NamespaceOverrides',
                serviceKey: event.serviceKey,
                namespaces: event.namespaces
            },
            previousData: {
                id: null,
                serviceKey: null,
                namespaces: null,
            }
        } as UpdateParams;
        dataProvider.create('logging', params).then((response) => {
            notify(`Create request sent`, { type: "success" });
            goToListTable();
        }).catch(async (error) => {
            if (error instanceof HttpError) {
                console.error("Error sending create request:", error);
                notify("Error creating override", { type: "error" });
            }
        })
    }

    const CustomToolbar = () => {
        const handleClick = () => {
            goToListTable();
        };

        return (<div>
            <SaveButton />
            <Button startIcon={<Cancel />} color="primary" onClick={handleClick}>
                Cancel
            </Button></div>
        );
    };
    return (
        <>
            {loading && <CircularProgress />}
            <Create>
                <SimpleForm onSubmit={handleCreate} toolbar={<CustomToolbar></CustomToolbar>}>
                    <SelectInput source="serviceKey" label="Service Key" required choices={entries} />
                    <ArrayInput source="namespaces">
                        <SimpleFormIterator inline>
                            <TextInput source="namespace" label="Namespace" required />
                            <SelectInput source="logLevel" label="Log Level" required choices={logTypeChoices} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    )
}