import {
    Datagrid,
    List,
    TextField,
    EditButton,
    useRecordContext,
    Link,
} from "react-admin";

export const ScheduleList = () => {
    const ViewInstances = () => {
        const record = useRecordContext();
        return (
            <Link
                to={{
                    pathname: `/instances`,
                    search: `filter=${JSON.stringify({
                        schedulerDefinitionId: [record.id],
                    })}`                 
                    
                }}
                onClick={(event) => event.stopPropagation()}
            >
                View Instances
            </Link>
        );
    };
    
    return (
        <List>
            <Datagrid>
                <TextField source="id" label="Schedule Definition Id" />
                <TextField source="requestingService" />
                <TextField source="scheduleKey" />
                <TextField source="name" />
                <TextField source="description" />
                <TextField source="schedulerType" />
                <TextField source="defaultScheduleTime" />
                <TextField source="allowManualExecution" />
                <TextField source="allowExecutionOverlap" />
                <TextField source="lifetime" />
                <TextField source="targetTopicName" />
                <TextField source="versionNumber" />
                <ViewInstances></ViewInstances>
                <EditButton ></EditButton>
            </Datagrid>
        </List>)
}