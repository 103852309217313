import {TextField, useRecordContext} from "react-admin";
import {Link} from "react-router-dom";
import React from "react";

const OrganizationLinkField = ({source, sx, label}: any) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Link to={`/organizations/${record.id}`} style={{textDecoration: 'none'}}>
            <TextField
                source={source}
                label={label}
                sx={sx}
            />
        </Link>
    );
};
export default OrganizationLinkField;