import {
    Edit,
    SimpleForm,
    TextInput,
    DateInput,
} from "react-admin";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const ScheduleInstanceEdit = (props: any) => {
    return (
        <Edit mutationMode="undoable"  >
        <SimpleForm toolbar={<EditToolbar />}>
            <TextInput source="id" disabled/>               
            <TextInput source="organizationKey" disabled/>
            <TextInput source="posOrganizationName" disabled/>
            <TextInput source="schedulerDefinitionName" disabled/>
            <TextInput source="schedulerInstanceJobKey" disabled/>
            <TextInput source="scheduleTime"  />
            <TextInput source="lifeTime"  />
            <TextInput source="sourceKey" disabled/>       
            <DateInput source="lastRequestDateTime" disabled />
            <DateInput source="nextEstimatedRequestDateTime" disabled/>
        </SimpleForm>
    </Edit>
)};