import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  minLength,
} from "react-admin";
import {
  countryChoices,
  phoneNumberFormat,
  stateChoices,
} from "../../utils/constants";
import { email } from "ra-core";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const OrganizationRequestEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="requesterName" required />
      <TextInput source="requesterEmail" validate={email()} required />
      <TextInput
        source="requesterPhone"
        parse={phoneNumberFormat}
        validate={minLength(14)}
      />
      <TextInput source="companyName" required />
      <TextInput source="companyAddress" />
      <TextInput source="knownAs" />
      <TextInput
        source="companyPhone"
        parse={phoneNumberFormat}
        validate={minLength(14)}
      />
      <SelectInput source="companyState" choices={stateChoices} />
      <TextInput source="companyCity" />
      <TextInput source="companyPostalCode" />
      <SelectInput
        source="companyCountry"
        choices={countryChoices}
        defaultValue="United States"
        required
      />
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Edit>
);
