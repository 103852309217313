import React, { useEffect, useState } from 'react';
import { Create, SelectInput, SimpleForm, useDataProvider } from 'react-admin';
import { externalTenantTypeChoices } from '../Organizations/common';
import {StripePrice, StripeProduct} from "../../utils/customDataProvider";


export const StripeConfigurationCreate = () => {
    const dataProvider = useDataProvider();
    const [products, setProducts] = useState<StripeProduct[]>([]);
    const [prices, setPrices] = useState<StripePrice[]>([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsResponse = await dataProvider.getStripeProducts();
                setProducts(productsResponse);
            } catch (error) {
                console.error('Failed to fetch products', error);
            }
        };

        fetchProducts();
    }, [dataProvider]);

    useEffect(() => {
        const fetchPrices = async () => {
            try {
                const pricesResponse = await dataProvider.getStripePrices('');
                setPrices(pricesResponse);
            } catch (error) {
                console.error('Failed to fetch prices', error);
            }
        };

        fetchPrices();
    }, [dataProvider]);

    return (
        <Create>
            <SimpleForm>
                <SelectInput
                    source="productId"
                    label="Product Id"
                    choices={products}
                    required
                />
                <SelectInput
                    source="priceId"
                    label="Price Id"
                    choices={prices}
                    required
                />
                <SelectInput
                    source="type"
                    label="Tenant Type"
                    choices={externalTenantTypeChoices}
                    required
                />
            </SimpleForm>
        </Create>
    );
};
