import { FaMap } from "react-icons/fa";
import {SectionMappingList} from "../components/Mapping/SectionMappingList";

const SectionMappings = {
  name: "section-mapping-section",
  icon: FaMap,
  list: SectionMappingList
};

export default SectionMappings;
