import React from "react";
import { SimpleForm, TextInput, Create } from "react-admin";

export const SettingCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="id" required label={"Key"} />
      <TextInput source="value" required />
    </SimpleForm>
  </Create>
);
