import { Edit, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, useRecordContext } from "react-admin";
import { EditNoDeleteToolbar } from "../CustomToolbar/EditNoDeleteToolbar";

const filterToQuery = (searchText: any) => ({ sectionName: `${searchText}` });

const SectionMappingForm = (props: any) => {
    const record = useRecordContext(props);
    return (
        <>
         <SimpleForm toolbar={<EditNoDeleteToolbar /> }>
            <TextInput source="venueName" disabled />
            <TextInput source="originationSectionName" disabled />
            <ReferenceInput source="masterVenueSectionId" reference="master-venue-section" filter={{ masterVenueId: record.masterVenueId }}>
                <AutocompleteInput optionText={(o) => o.sectionName} filterToQuery={filterToQuery} />
            </ReferenceInput>
        </SimpleForm>
        </>
    );
};

export const VenueSectionMappingEdit = (props: any) => {
  return (
  <Edit mutationMode="pessimistic" {...props} >
    <SectionMappingForm />
  </Edit>
)};
