import { FaMap } from "react-icons/fa";
import { ProductionMappingsEdit } from "../components/ProductionMappings/ProductionMappingsEdit";
import { ProductionMappingsList } from "../components/ProductionMappings/ProductionMappingsList";

const ProductionMappings = {
    name: "priority-production",
    icon: FaMap, 
    list: ProductionMappingsList,
    edit: ProductionMappingsEdit,
    menuLabel: "Priority Production",
};

export default ProductionMappings;
