import {
  Datagrid,
  List,
  TextField,
  DateField,
  NumberInput,
  TextInput,
} from "react-admin";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import { ShowAuditEventData } from "./ShowAuditEventData";

const auditsFilter = [
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="Organization"
  />,
  <NumberInput
    label="ProductionId"
    source="productionId"
  />,
  <TextInput source="correlationId" label="CorrelationId" />,
];

export const ProductionAssignmentAuditList = () => (
  <List filters={auditsFilter} sort={{ field: "ts", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} expand={ShowAuditEventData}>
      <DateField
        source="ts"
        label="DateTime"
        showTime
        options={{ timeZone: "UTC", timeZoneName: "short" }}
      />
      <TextField source="productionId" label="ProductionId" />
      <TextField source="organizationKey" label="OrganizationKey" />
      <TextField source="auditOrganizationKey" label="AuditOrganizationKey" />
      <TextField source="auditUsername" label="AuditUsername" />
      <TextField source="id" label="Id" sortable={false} />
      <TextField source="correlationId" label="CorrelationId" sortable={false} />
    </Datagrid>
  </List>
);
