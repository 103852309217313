import { useDataProvider, useNotify, useRecordContext } from "react-admin";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {Tooltip} from "@mui/material";

const VenueMappingRemoveButton = (props:any) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleRemove = async () => {
        var data = {
            sourceType: record.sourceType,
            sourceKey: record.sourceKey
        }
        try {
            await dataProvider.removeVenueMapping({ data });
            props.setMappingSuccessful(false);
            props.setMappedMasterVenueId(null);
            notify(`Mapping Removed`, { type: "success" });
        }
        catch (error: any) {
            notify(error.body[0].errorMessage || 'An unknown error occurred', { type: "error" });
        }
    }
    return <IconButton aria-label="remove" onClick={handleRemove}>
        <Tooltip title={"Remove Mapping"}>
            <DeleteIcon color="error" />
        </Tooltip>
    </IconButton>
}
export default VenueMappingRemoveButton;