import { FaMap } from "react-icons/fa";
import { VenueSectionMappingList } from "../components/Mapping/VenueSectionMappingList";
import { VenueSectionMappingEdit } from "../components/Mapping/VenueSectionMappingEdit";

const VenueSectionMappings = {
  name: "section-mapping-venue",
  icon: FaMap,
  list: VenueSectionMappingList,
  edit: VenueSectionMappingEdit,
  menuLabel: "Section Mapping",
};

export default VenueSectionMappings;
