import {
  Create,
  minLength,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
} from "react-admin";
import { phoneNumberFormat } from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { email } from "ra-core";
import {
  errorMap,
} from "../../utils/errors";

export const InvitationCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const organizationIdAsString = searchParams.get("organizationId") ?? "0";
  const organizationId = parseInt(organizationIdAsString);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleCreate = async (formData: any) => {
    try {
      await dataProvider.sendInvitation({ data: formData });
      navigate("/invitations");
    } catch (error: any) {
      const errorCode = error.body[0].errorCode;
      const errorMessage = error.body[0].errorMessage;

      if (errorMessage) notify(errorMessage, { type: "error" });
      else notify(errorMap[errorCode], { type: "error" });
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleCreate}>
        <TextInput source="displayName" label="Full Name" required />
        <TextInput source="email" validate={email()} required />
        <TextInput
          source="phone"
          parse={phoneNumberFormat}
          format={phoneNumberFormat}
          validate={minLength(14)}
        />
       
        <ReferenceInput
          source="organizationId"
           reference="organization-details"
          required
          filter={{ status_eq: ["Active", "Pending"] }}
          perPage={99999}
        >
          <SelectInput optionText="name" defaultValue={organizationId} />
        </ReferenceInput>
        <ReferenceInput source="defaultUserRole" reference="roles" required>
          <SelectInput optionText="name" required/>
        </ReferenceInput>
        <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
      </SimpleForm>
    </Create>
  );
};
