import { FaUserAlt } from "react-icons/fa";
import { MemberCreate } from "../components/Members/MemberCreate";
import MemberEdit from "../components/Members/MemberEdit";
import { MemberList } from "../components/Members/MemberList";

const Members = {
  name: "members",
  icon: FaUserAlt,
  list: MemberList,
  edit: MemberEdit,
  create: MemberCreate,
  menuLabel: "Members",
};

export default Members;
