import { FaSlidersH } from "react-icons/fa";
import { SettingCreate } from "../components/Settings/SettingCreate";
import { SettingEdit } from "../components/Settings/SettingEdit";
import { SettingList } from "../components/Settings/SettingsList";

const Settings = {
  name: "settings",
  icon: FaSlidersH,
  list: SettingList,
  edit: SettingEdit,
  create: SettingCreate,
  menuLabel: "App. Settings",
};

export default Settings;
