import React from 'react';
import {
    List, Datagrid, TextField, TextInput, SelectInput
} from 'react-admin';
import {
    PricingModeChoices,
    TenantTypeChoices,
} from './common';
import BulkUpdateToolbar from './BulkUpdateToolbar';
import {MarketplaceSourceTypesChoices, organizationStatusChoices} from "../common";

const OrganizationFilter = [
    <TextInput label="Name" source="name" alwaysOn />,
    <SelectInput label="Comparable Marketplace Source" source="comparableMarketplaceSource" choices={MarketplaceSourceTypesChoices} />,
    <SelectInput label="External Tenant Type" source="externalTenantType" choices={TenantTypeChoices} />,
    <SelectInput label="Status" source="status" choices={organizationStatusChoices} />,
    <SelectInput label="Pricing Mode" source="organizationPricingMode" choices={PricingModeChoices} />
];

export const OrganizationsPriceModeComponent = () => {
    return (
        <List filters={OrganizationFilter} sort={{ field: "name", order: "DESC" }}>
            <Datagrid bulkActionButtons={
                    <BulkUpdateToolbar />
            }><TextField source="name" />
                <TextField source="knownAs" />
                <TextField source="comparableMarketplaceSource" />
                <TextField source="externalTenantType" />
                <TextField source="status" />
                <TextField source="organizationPricingMode" />
                <TextField source="lastModifiedBy" />
                <TextField source="lastModifiedDate" />                
            </Datagrid>
        </List>
    );
};

export default OrganizationsPriceModeComponent;
