import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const RoleEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="key" disabled />
      <TextInput source="name" required />
      <ReferenceArrayInput source="permissionIds"  reference="permissions/org-permissions" >
        <AutocompleteArrayInput optionText="name"/>
      </ReferenceArrayInput>
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Edit>
);
