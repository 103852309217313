import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  Labeled,
} from "react-admin";

import { EditToolbar } from "../CustomToolbar/EditToolbar";
import { JsonInput } from "react-admin-json-view";

import OrganizationLink from "../Common/OrganizationLink";


export const PricingGroupEdit = () => {
  const transform = (data:any) => ({
    ...data,
    pricingGroupRequestRanks: data.pricingGroupTickets
  });
  return (
  <Edit mutationMode="pessimistic" transform={transform}>
    <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceManyField
            source="organizationKey"
            target="key"
            reference="organization-details"
            label="Organization"
        >
            <SingleFieldList>
                <OrganizationLink>
                    <Labeled>
                        <TextField
                            source="name"
                            label="Organization"
                            sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
                        />
                    </Labeled>
                </OrganizationLink>
            </SingleFieldList>
        </ReferenceManyField>
      <ReferenceManyField
        source="productionId"
        target="id"
        reference="productions"
        label="Production"
      >
        <SingleFieldList>
          <Labeled>
            <TextField
              source="productionName"
              label="Production"
              sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
            />
          </Labeled>
        </SingleFieldList>
      </ReferenceManyField>
      <TextInput source="name" />
      <NumberInput source="ceilingPrice" />
      <NumberInput source="floorPrice" />

      <JsonInput
          source="pricingGroupTickets"
          label = "Pricing Group Tickets"
          jsonString={false}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: false,
          }}
          
        />
      <JsonInput
        source="ruleSet"
        jsonString={false}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <JsonInput
        source="marketGroupCriteria"
        jsonString={false}
        reactJsonOptions={{
          // Props passed to react-json-view
          name: null,
          collapsed: true,
          enableClipboard: false,
          displayDataTypes: false,
        }}
      />
      <BooleanInput source="isAutoPricingEnabled" />
      
    </SimpleForm>
  </Edit>
  )
      };
