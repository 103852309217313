import { DataProvider, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import React from "react";

const productionMappingsCustomDataProvider = (
    token: string,
    baseUrl: string,
    setIsTokenExpired?: React.Dispatch<React.SetStateAction<boolean>>
): DataProvider => {
    const be_base_url: string = baseUrl;
    const httpClient = async (url: string, options: any = {}) => {
        const headers =
            options.headers instanceof Headers
                ? options.headers
                : new Headers(options.headers || {});
        if (!headers.has("Accept")) {
            headers.set("Accept", "application/json");
        }
        if (token) {
            if (isTokenExpired(token)) {
                if (setIsTokenExpired) setIsTokenExpired(true);
            }
            headers.set("Authorization", `Bearer ${token}`);
        }
        const fetchOptions = { ...options, headers };
        return fetchUtils.fetchJson(url, fetchOptions);
    };
    const dataProvider = simpleRestProvider(be_base_url, httpClient);

    const originalGetList = dataProvider.getList;
    dataProvider.getList = (resource, params) => {
        const actualResource = resource === 'priority-production' ? 'production-mappings' : resource;
        return originalGetList(actualResource, params);
    };

    return {
        ...dataProvider
    };
};

const isTokenExpired = (token: string) => {
    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) {
        return false;
    }

    const decodedPayload = JSON.parse(atob(tokenParts[1]));
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const tokenExpirationTimeInSeconds = decodedPayload.exp;

    return currentTimeInSeconds >= tokenExpirationTimeInSeconds;
};

export default productionMappingsCustomDataProvider;
