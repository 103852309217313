import {FaPowerOff} from "react-icons/fa";
import OrganizationPriceModeList from "../components/Organizations/OrganizationPricingMode/OrganizationPricingMode";

const OrganizationPriceMode = {
  name: "organization-pricing-setting",
  icon: FaPowerOff,
  list: OrganizationPriceModeList,
  menuLabel: "Org Price Mode"
};

export default OrganizationPriceMode;
