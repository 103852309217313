import {
  Datagrid,
  List,
  TextField,
  DateField,
  NumberInput,
  TextInput,
} from "react-admin";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import { ShowAuditEventData } from "./ShowAuditEventData";

const auditsFilter = [
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="Organization"
  />,
  <NumberInput
    label="TicketGroupId"
    source="ticketGroupId"
  />,
  <TextInput source="sourceKey" label="SourceKey" />,
  <NumberInput
  label="PricingGroupId"
  source="pricingGroupId"
/>,
  <TextInput source="correlationId" label="CorrelationId" />,
];

export const PricerOrganizationInventoryAuditList = () => (
  <List filters={auditsFilter} sort={{ field: "ts", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} expand={ShowAuditEventData}>
      <DateField
        source="ts"
        label="DateTime"
        showTime
        options={{ timeZone: "UTC", timeZoneName: "short" }}
      />
      <TextField source="ticketGroupId" label="TicketGroupId" />
      <TextField source="sourceKey" label="SourceKey" />
      <TextField source="organizationKey" label="OrganizationKey" />
      <TextField source="pricingGroupId" label="PricingGroupId" />
      <TextField source="auditOrganizationKey" label="AuditOrganizationKey" />
      <TextField source="auditUsername" label="AuditUsername" />
      <TextField source="id" label="Id" sortable={false} />
      <TextField source="correlationId" label="CorrelationId" sortable={false} />
    </Datagrid>
  </List>
);
