import { FaHistory } from "react-icons/fa";
import { UserInvitationAuditList } from "../components/Audits/UserInvitationAuditList";

const UserInvitationAudits = {
  name: "user-invitation-audits",
  icon: FaHistory,
  list: UserInvitationAuditList,
  menuLabel: "User Invitations",
};

export default UserInvitationAudits;
