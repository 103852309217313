import {Datagrid, List, TextField, TextInput, useRecordContext} from "react-admin"
import {FC} from "react";
import {Link} from "react-router-dom";

interface OrganizationFieldProps {
    source?: string;
}

const OrganizationNameField: FC<OrganizationFieldProps> = ({source}) => {
    const record = useRecordContext();
    return record ? (
        <Link to={`/organizations/?filter=${JSON.stringify({
            key: [record.id],
        })}`}>
            {`${record.organizationName}`}
        </Link>
    ) : null;
};

const OrganizationKeyField: FC<OrganizationFieldProps>  = ({source}) => {
    const record = useRecordContext();
    return record ? (
        <Link to={`/organizations/?filter=${JSON.stringify({
            key: [record.id],
        })}`}>
            {`${record.organizationKey}`}
        </Link>
    ) : null;
};

export const OrgBusinessSummaryList = () => {
    
    const filters = [
        <TextInput
            source="organizationName"
            label={"Organization Name"}
            alwaysOn />,
        <TextInput
            source="organizationKey"
            label={"Organization Key"}
            alwaysOn />,
        
    ];
  
    return <List filters={filters} sort={{ field: 'organizationKey', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false} >
            <OrganizationNameField source="organizationName"/>
            <OrganizationKeyField source="organizationKey"/>
            <TextField source="pricingGroupCount"  />
            <TextField source="totalPricedTicketGroups"  />
            <TextField source="totalAvailableTicketGroups"  />
        </Datagrid>
    </List>
}
