import { DataProvider, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

const schedulerCustomDataProvider = (
  token: string,
  baseUrl: string,
  setIsTokenExpired?: React.Dispatch<React.SetStateAction<boolean>>
): DataProvider => {
  const be_base_url: string = baseUrl;
  const httpClient = async (url: string, options: any = {}) => {
    const headers =
      options.headers instanceof Headers
        ? options.headers
        : new Headers(options.headers || {});
    if (!headers.has("Accept")) {
      headers.set("Accept", "application/json");
    }
    if (token) {
      if (isTokenExpired(token)) {
        if (setIsTokenExpired) setIsTokenExpired(true);
      }
      headers.set("Authorization", `Bearer ${token}`);
    }
    const fetchOptions = { ...options, headers };
    return fetchUtils.fetchJson(url, fetchOptions);
  };
  const dataProvider = simpleRestProvider(be_base_url, httpClient);

  dataProvider.update = (resource, params) => {
    let updatePath = '';
    switch (resource) {
      case "schedules":
        params.data.schedulerDefinitionId = params.data.id;
        updatePath = `/schedules/${params.data.id}`;
        break;
      case "instances":
        params.data.schedulerInstanceId = params.data.id;
        updatePath = `instances/${params.data.id}`;
        break;
      default:
        updatePath = ``;
    }
    
    return httpClient(`${be_base_url}/${updatePath}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
  }).then(({ json }) => ({ data: json }));
  }
  


 
  return {
    ...dataProvider
  };
};

const isTokenExpired = (token: string) => {
  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    return false;
  }

  const decodedPayload = JSON.parse(atob(tokenParts[1]));
  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const tokenExpirationTimeInSeconds = decodedPayload.exp;

  return currentTimeInSeconds >= tokenExpirationTimeInSeconds;
};

export default schedulerCustomDataProvider;
