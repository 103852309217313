import { FaHistory } from "react-icons/fa";
import { PricingGroupAuditList } from "../components/Audits/PricingGroupAuditList";

const PricingGroupAudits = {
  name: "pricing-group-audits",
  icon: FaHistory,
  list: PricingGroupAuditList,
  menuLabel: "Pricing Groups",
};

export default PricingGroupAudits;
