import { useDataProvider, useNotify, useRecordContext} from "react-admin";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const SourceProductionMappingRemoveButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleRemove = async () => {
        var data = {
            SourceProductionKey: record.sourceProductionKey,
            SourceType: record.sourceType,
            Classification: record.classification,
        }
        try {
            await dataProvider.removeSourceProductionMapping({data});
            notify(`Mapping Removed`, { type: "success" });
        }
        catch (error: any) {
            notify(error.body[0].errorMessage || 'An unknown error occurred', { type: "error" });
        }
    }
    return <IconButton aria-label="delete" onClick={handleRemove}>
        <DeleteIcon color="error"/>
    </IconButton>
}
export default SourceProductionMappingRemoveButton;