import { FaBars } from "react-icons/fa";
import { LoggingDefaultEdit } from "../components/Logging/LoggingDefaults/LoggingDefaultEdit";
import { LoggingDefaultCreate } from "../components/Logging/LoggingDefaults/LoggingDefaultCreate";
import { LoggingDefaultList } from "../components/Logging/LoggingDefaults/LoggingDefaultList";


const NamespaceLoggingDefaults = {
    name: "logging/defaults",
    icon: FaBars,
    list: LoggingDefaultList,
    edit: LoggingDefaultEdit,
    create: LoggingDefaultCreate,
    menuLabel: "Defaults",
};

export default NamespaceLoggingDefaults;