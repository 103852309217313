import { useEffect, useState } from "react";
import { Create, SelectInput, SimpleForm, useDataProvider, useNotify, SaveButton, UpdateParams, HttpError } from "react-admin";
import { logTypeChoices, OverrideTypes } from "../common";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Cancel from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

export const LoggingDefaultCreate = () => {
    const dataProvider = useDataProvider();
    const navigate = useNavigate();
    const notify = useNotify();
    const [entries, setEntries] = useState<OverrideTypes[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        const getEntries = () => {
            setLoading(true);
            dataProvider
                .getLoggingServiceKeys()
                .then((data: any) => {
                    setEntries(data.json.map((option: string) => ({ id: option, name: option })));
                    setLoading(false);
                });
        };

        getEntries();
    }, [dataProvider]);

    const goToListTable = () => {
        navigate('/logging/defaults');
    }

    const handleCreate = (event: any) => {
        const params = {
            id: event.id,
            data: {
                id: event.serviceKey + ':Logging:DefaultLogLevel',
                value: event.value,
                type: event.serviceKey
            },
            previousData: {
                id: null,
                value: null,
                type: null,
            }
        } as UpdateParams;
        dataProvider.create('logging/defaults', params).then((response) => {
            notify(`Create request sent`, { type: "success" });
            goToListTable();
        }).catch(async (error) => {
            if (error instanceof HttpError) {
                console.error("Error sending create request:", error);
                notify("Error creating default", { type: "error" });
            }
        })
    }

    const CustomToolbar = () => {
        const handleClick = () => {
            goToListTable();
        };

        return (<div>
            <SaveButton />
            <Button startIcon={<Cancel />} color="primary" onClick={handleClick}>
                Cancel
            </Button></div>
        );
    };

    return (
        <>
            {loading && <CircularProgress />}
            <Create>
                <SimpleForm onSubmit={handleCreate} toolbar={<CustomToolbar></CustomToolbar>}>
                    <SelectInput source="serviceKey" label="Service Key" required choices={entries} />
                    <SelectInput source="value" label="Log Level" required choices={logTypeChoices} />
                </SimpleForm>
            </Create>
        </>
    )
};