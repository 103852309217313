import React from "react";
import { useMsal } from "@azure/msal-react";
import ExitIcon from "@mui/icons-material/PowerSettingsNew";
import { MenuItem, Typography } from "@mui/material";
import { forwardRef } from "react";

export const SignOutButton = forwardRef(
  (props, ref: React.ForwardedRef<HTMLLIElement>) => {
    const { instance } = useMsal();

    const handleLogout = () => {
      instance.logoutRedirect().catch((e) => {
        console.log(e);
      });
    };

    return (
      <MenuItem onClick={handleLogout} ref={ref}>
        <ExitIcon /> <Typography mx={0.5}> Logout</Typography>
      </MenuItem>
    );
  }
);
