import {
  Edit,
  SimpleForm,
  TextInput,
  AutocompleteInput,
  ReferenceManyField,
  SingleFieldList,
  Labeled,
  useDataProvider,
  AutocompleteInputProps,
  useRecordContext,
  TextField
} from "react-admin";

import { EditToolbar } from "../CustomToolbar/EditToolbar";

import { Identifier } from 'ra-core';
import React, { useEffect, useState } from "react";
import { MemberStatuses } from "../../utils/constants";

import OrganizationLink from "../Common/OrganizationLink";

interface Member {
  id: Identifier;
  username: string;
  name: string; 
}

const MemberAutocompleteInput: React.FC<AutocompleteInputProps> = ({ source }) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const [choices, setChoices] = useState<Member[]>([]);

  useEffect(() => {
    const getChoices = async () => {
      const orgeResp = await dataProvider.getList<Member>('organizations', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: {key: record.organizationKey} 
      });
      const org = orgeResp.data[0];

      const { data } = await dataProvider.getList<Member>('members', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
        filter: {status: MemberStatuses.Active, organizationId: org.id} 
      });
      setChoices(data);
    }
      getChoices(); 
  }, [dataProvider, record.organizationKey]);

  return (
      <AutocompleteInput
          source={source}
          choices={choices}
          optionText="name"
          optionValue="username"
      />
  );
};

export default MemberAutocompleteInput;
export const ProductionAssignmentEdit = () => {
  
  return (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <ReferenceManyField
          source="organizationKey"
          target="key"
          reference="organization-details"
          label="Organization"
      >
        <SingleFieldList>
          <OrganizationLink>
            <Labeled>
              <TextField
                  source="name"
                  label="Organization"
                  sx={{ margin: "5px 0px 15px", display: "inline-flex" }}
              />
            </Labeled>
          </OrganizationLink>
        </SingleFieldList>
      </ReferenceManyField>

      <MemberAutocompleteInput source="username" />
      <TextInput source="productionId" label="Production ID" disabled/>
      <TextInput source="productionName" label="Production Name" disabled/>
      <TextInput source="venueName" label="Venue" disabled/>
      <TextInput source="productionDate" label="Production Date" disabled/>
      

      
      
    </SimpleForm>
  </Edit>
  )
      };
