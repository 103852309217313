import { FaMap } from "react-icons/fa";
import {PerformerMappingsList} from "../components/PeformerMappings/PerformerMappingsList";

const PerformerMappings = {
    name: "source-performers",
    icon: FaMap, 
    list: PerformerMappingsList,
    menuLabel: "Performer Mappings",
};

export default PerformerMappings;
