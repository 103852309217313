import { FaFileInvoiceDollar } from "react-icons/fa";
import { PricingGroupList } from "../components/PricingGroups/PricingGroupList";
import { PricingGroupEdit } from "../components/PricingGroups/PricingGroupEdit";

const PricingGroups = {
  name: "pricing-groups",
  icon: FaFileInvoiceDollar,
  list: PricingGroupList,
  // create: PricingGroupCreate,
  edit: PricingGroupEdit,
  menuLabel: "Pricing Groups",
};

export default PricingGroups;
