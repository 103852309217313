import { useEffect } from "react";
import Container from "@mui/material/Container";
import { Box, Button, CircularProgress } from "@mui/material";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import { useNavigate } from "react-router-dom";
import { authConstants } from "../../utils/constants";
import Typography from "@mui/material/Typography";
import { getEnvironment } from "../../utils/helpers";

interface SignInPageProps {}

const SignInPage: React.FC<SignInPageProps> = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const handleLogin = (loginType: string) => {
    if (loginType === "redirect") {
      if (window.location.href !== authConstants.LogoutUrl) {
        window.history.replaceState({}, "", authConstants.LogoutUrl);
      }
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  const envString = getEnvironment();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }

    if (envString) {
      document.title = `[${envString}] PRQX Admin`;
    } 
    else {
        document.title = `PRQX Admin Portal`;
    }
  }, [envString, isAuthenticated, navigate]);

  const showLoading =
    inProgress === InteractionStatus.Startup ||
    inProgress === InteractionStatus.HandleRedirect;

  return (
    <Container maxWidth="sm" sx={styles.container}>
      <Box
        component="img"
        sx={styles.image}
        alt="PricerQX"
        src={"/logo-big.png"}
      />
      <Typography variant="h4" gutterBottom>
        {envString && `You are signing in to ${envString}-ENV`}
      </Typography>

      {showLoading ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" onClick={() => handleLogin("redirect")}>
          Sign in
        </Button>
      )}
    </Container>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: "auto",
    width: 640,
  },
};

export default SignInPage;
