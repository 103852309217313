import { FaMailBulk } from "react-icons/fa";
import { InvitationCreate } from "../components/Invitations/InvitationCreate";
import { InvitationEdit } from "../components/Invitations/InvitationEdit";
import { InvitationList } from "../components/Invitations/InvitationList";

const Invitations = {
  name: "invitations",
  icon: FaMailBulk,
  list: InvitationList,
  edit: InvitationEdit,
  create: InvitationCreate,
  menuLabel: "Invitations",
};

export default Invitations;
