import { FaFileInvoiceDollar } from "react-icons/fa";
import {OrgBusinessSummaryList} from "../components/OrgBusinessSummary/OrgBusinessSummaryList";


const OrgBusinessSummary = {
  name: "org-business-summary",
  icon: FaFileInvoiceDollar,
  list: OrgBusinessSummaryList,
  menuLabel: "Org. Business Summary",
};

export default OrgBusinessSummary;