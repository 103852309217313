import React from 'react';
import { FieldProps, RaRecord, useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { format, parseISO } from "date-fns";

interface CombinedDateTimeProps extends FieldProps {
    sourceDate: string;
    sourceTime: string;
    isComplexObject?: boolean;
}

export const DateTimeDisplay = ({ sourceDate, sourceTime }: CombinedDateTimeProps) => {
    const combinedDateTimeISO = `${sourceDate}T${sourceTime}`;
    const dateObject = parseISO(combinedDateTimeISO);
    return format(dateObject, "EEE•d MMM yyyy h:mm a");
};

const getDateTime = (record: RaRecord, sourceDate: string, sourceTime: string): CombinedDateTimeProps | null => {
    var dateProperties = sourceDate.split('.');
    var timeProperties = sourceTime?.split('.');
    if (dateProperties.length == 2)
        return { sourceDate: record[dateProperties[0]][dateProperties[1]], sourceTime: timeProperties? record[timeProperties[0]][timeProperties[1]]:'' } as CombinedDateTimeProps;
    return null;
}

export const CombinedDateTimeField = ({ sourceDate, sourceTime, isComplexObject = false }: CombinedDateTimeProps) => {
    const record = useRecordContext();
    if (!record) return null;
    var dateTimeValues = isComplexObject ? getDateTime(record, sourceDate, sourceTime) : null;
    const date = dateTimeValues ? dateTimeValues.sourceDate : record[sourceDate];
    const time = dateTimeValues ? dateTimeValues.sourceTime : record[sourceTime];
    const dateTime = DateTimeDisplay({ sourceDate: date, sourceTime: time } as CombinedDateTimeProps);

    return (
        <div>
            <Typography variant="body2" style={{ fontSize: '0.8rem', whiteSpace: 'nowrap' }}>
                {dateTime}
            </Typography>
        </div>
    );
};
