import { DeleteButton, SaveButton, Toolbar } from "react-admin";
import { CancelButton } from '../../components/CustomToolbar/CancelButton'

export const EditToolbar = () => {
    return (
        <Toolbar>
          <div>
            <SaveButton />
            <CancelButton />
          </div>
            <div style={{ marginLeft: 'auto', marginRight: '0px' }}>
            <DeleteButton />
          </div>   
        </Toolbar>
    );
};