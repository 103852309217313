import React, { ChangeEvent, useState } from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  minLength,
} from "react-admin";
import { externalTenantTypeChoices, organizationStatusChoices } from "./common";
import {
  ExternalTenantTypes,
  countryChoices,
  phoneNumberFormat,
  stateChoices, comparableMarketplaceSourceChoices,
} from "../../utils/constants";
import { email } from "ra-core";

interface FormData {
  organizationKey: string;
  externalTenantType: string;
}
export const OrganizationCreate = () => {

  const [showOrgKeyInput, setShowOrgKeyInput] = useState(false);

  const extTypesThatRequireManualKey = [ExternalTenantTypes.Skybox];

  const prefixMap: Record<string, string> = {};
  prefixMap[ExternalTenantTypes.Skybox] = "sb-";

  const handleExternalTenantTypeSelectChange = (
    event: ChangeEvent<{ name?: string; value: any }>
  ) => {
    // Check if a certain value was selected
    if (extTypesThatRequireManualKey.includes(event.target.value)) {
      setShowOrgKeyInput(true);
    } else {
      setShowOrgKeyInput(false);
    }
  };

  const transform = (data: FormData) => {
    data.organizationKey = `${prefixMap[data.externalTenantType]}${data.organizationKey}`
    return data;
};
  return (
  <Create transform={transform}>
    <SimpleForm>
     
      {showOrgKeyInput && (
        <TextInput source="organizationKey" required />
      )}
      <TextInput source="name" required />
      <TextInput source="knownAs" />
      <TextInput source="primaryContact" />
      <TextInput source="email" validate={email()} required />
      <TextInput
        source="phone"
        parse={phoneNumberFormat}
        validate={minLength(14)}
        required
      />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" required />
      <SelectInput source="state" choices={stateChoices} required />
      <TextInput source="postalcode" required />
      <SelectInput
        source="country"
        choices={countryChoices}
        defaultValue="United States"
        required
      />
      <SelectInput
        source="status"
        choices={organizationStatusChoices}
        required
      />
      <SelectInput
        source="comparableMarketplaceSource"
        choices={comparableMarketplaceSourceChoices}
        required
      />
      <SelectInput
        source="externalTenantType"
        choices={externalTenantTypeChoices}
        onChange={(event: any) => handleExternalTenantTypeSelectChange(event)}
        required
      />
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Create>
  )
}
