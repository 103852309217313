import { FaPaperPlane } from "react-icons/fa";
import { NotificationsList } from "../components/Notifications/NotificationsList";

const Notifications = {
  name: "notifications",
  icon: FaPaperPlane,
  list: NotificationsList,
  menuLabel: "Notifications",
};

export default Notifications;
