import { FaCcStripe } from "react-icons/fa";
import { StripeConfigurationsList } from "../components/StripeConfigurations/StripeConfigurationList";
import { StripeConfigurationEdit } from "../components/StripeConfigurations/StripeConfigurationEdit";
import { StripeConfigurationCreate } from "../components/StripeConfigurations/StripeConfigurationCreate";

const StripeConfigurations = {
    name: "stripe-configurations",
    icon: FaCcStripe,
    list: StripeConfigurationsList,
    edit: StripeConfigurationEdit,
    create: StripeConfigurationCreate,
    menuLabel: "Stripe Configurations"
};

export default StripeConfigurations;