import {useRecordContext} from "react-admin";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React from "react";
import {Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {MasterProductionChild} from "./MasterProductionChild";
import {DateTimeDisplay} from "../../utils/CombinedDateTimeField";
import SourceProductionMappingRemoveButton from "./SourceProductionMappingRemoveButton";

export const SourceProductionMappingMasterProduction = () => {
    const record = useRecordContext();
    const masterProduction = record.masterProduction;
    const sourceProduction = record.sourceProduction;
    return (
        <Card sx={{margin: "0.2rem", padding: "0.5rem"}}>
            
            <Typography variant={"h6"} sx={{margin: "0.5rem"}}>Master Production</Typography>
            {record.masterProductionId ?
                <div style={{backgroundColor: "rgba(33, 150, 243, 0.1)", padding: "1rem", borderRadius: "3px"}}>
                    <Typography variant={"body1"} sx={{margin: "0.5rem", fontWeight: "bold"}}>Currently mapped master production</Typography>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Venue</TableCell>
                                <TableCell align="left">Venue Location</TableCell>
                                <TableCell align="left">Classification</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">{record.masterProductionId}</TableCell>
                                <TableCell align="left">{masterProduction.productionName}</TableCell>
                                <TableCell align="left">{DateTimeDisplay({sourceDate: masterProduction.localDate, sourceTime: masterProduction.localTime})}</TableCell>
                                <TableCell align="left">{masterProduction.venueName}</TableCell>
                                <TableCell align="left">{`${masterProduction.venueCity}, ${masterProduction.venueState}`}</TableCell>
                                <TableCell align="left">{masterProduction.classification}</TableCell>
                                <SourceProductionMappingRemoveButton />
                            </TableRow>
                        </TableBody>
                    </Table>
                    </div>
                    :
                    <Typography variant={"body1"} sx={{margin: "1rem"}}>Master production not mapped</Typography>
                
                
            }
           <MasterProductionChild sourceProductionKey={record.sourceProductionKey} sourceType={record.sourceType} localDate={sourceProduction.localDate} classification={sourceProduction.classification} venue={sourceProduction.venueName} masterProductionId={record.masterProductionId}/>
        </Card>          
    );
};