import { BooleanField, Datagrid, DateField, Identifier, List, SearchInput, SelectInput, TextField, ThemeProvider, useDataProvider } from "react-admin"
import { FormattedVenueField } from "./FormattedVenueField";
import { FormattedPricingCriteriaField } from "./FormattedPricingCriteria";
import { FormattedMarketComparablesField } from "./FormattedMarketComparablesField";
import { useCallback, useEffect, useState } from "react";
import { MappingTheme } from "../ProductionMappings/MappingTheme";
import { TemplateProductionsExpandedRow } from "./TemplateProductionsExpandedRow";

const styles = {
    fontSize: '.80rem'
}
type ExpandRowProps = {
    id: Identifier;
    record: any;
    resource: string;
};

export const PricingTemplatesList = () => {

    const dataProvider = useDataProvider();
    const [entries, setEntries] = useState<any[]>([]);

    const getEntries = useCallback(() => {
        dataProvider
            .getOrganizations()
            .then((data: any) => {
                setEntries(data)
            });
    }, [dataProvider]);

    useEffect(() => {
        getEntries();
    }, [getEntries]);

    const filters = [
        <SearchInput
            source="searchText"
            alwaysOn />,

        <SelectInput
            label="Organization"
            alwaysOn
            emptyText={"Select an Organization"}
            key="organizationKey"
            source="organizationKey"
            optionValue="organizationKey"
            hiddenLabel={false}
            choices={entries}
            required
            optionText={(record: { name: any; knownAs: any; id: any }) =>
                `${record.name} [${record.knownAs ?? ''} - ${record.id}]`
            }
        />,

        <SelectInput
            label='Status'
            emptyText={'All'}
            source="showDeleted"
            choices={[{ id: 'true', name: 'Only Deleted' }, { id: 'false', name: 'Only Active' }]}

        />
    ];
    const expandRow = ({ id, record }: ExpandRowProps) => {
        return <ThemeProvider theme={MappingTheme}>

            <TemplateProductionsExpandedRow
                templateId={id.toString()}
            />
        </ThemeProvider>;
    };
    return <List filters={filters} filterDefaultValues={{ showDeleted: false }} debounce={500} empty={false} >
        <Datagrid bulkActionButtons={false} expand={expandRow} expandSingle >
            <TextField source="pricingTemplateId" label="Id" />
            <TextField source="name" fullWidth sx={styles} sortable={false} />
            <FormattedVenueField label="Venue" sortable={false} />
            <TextField source="primaryPerformerName" label="Primary Performer" sx={styles} sortable={false} />
            <TextField source="pricingGroupCount" label="Pricing Groups" sortable={false} />
            <FormattedPricingCriteriaField label="Pricing Criteria" sortable={false} />
            <FormattedMarketComparablesField label="Market Comparables" sortable={false} />
            <DateField source="createdDate" showTime sx={styles} />
            <TextField source="createdBy" sx={styles} />
            <DateField source="lastModifiedDate" label="Updated Date" showTime sx={styles} />
            <DateField source="lastUsedDate" label="Last Used" showTime sx={styles} />
            <TextField source="lastModifiedBy" sx={styles} />
            <BooleanField source="isDeleted" sortable={false} label="Deleted" />
        </Datagrid>
    </List>
}
