import { format } from "date-fns";
import { Datagrid, DateField, DateTimeInput, FunctionField, List, TextField, TextInput } from "react-admin";
import { formatDateWithoutTZ, parseDateToUTC } from "../../utils/helpers";

const performerFilter = [
  <TextInput source="id" />,
  <TextInput source="name" />,
  <DateTimeInput
    label="Created Date from"
    source="createdDate_gte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Created Date to"
    source="createdDate_lte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Last modified from"
    source="lastModifiedDate_gte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Last modified to"
    source="lastModifiedDate_lte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
];

export const PerformerList = () => (
  <List filters={performerFilter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="createdDate" showTime/>
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedDate: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedDate),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
    </Datagrid>
  </List>
);
