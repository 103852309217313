import { FC } from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  FunctionField,
  useRecordContext,
  DateField,
  DateInput,
  BooleanField,
  NumberField,
  SingleFieldList,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  NumberInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayField,
  FieldProps,
  Link,
} from "react-admin";

import CheckIcon from "@mui/icons-material/Check";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { productionStatusChoices } from "./common";
import { performerFilterToQuery, venueFilterToQuery } from "../../utils/helpers";

interface PerformerRecord {
  isPrimary: boolean;
  name: string;
  id: string;
}

interface PerformerFieldProps {
  record?: PerformerRecord;
}

interface CustomTimeProps extends FieldProps {
  source: string;
  record?: Record<string, any>;
}

const CustomTimeField: FC<CustomTimeProps> = ({ source }) => {
  const record = useRecordContext();
  const timeValue = record ? record[source] : null;
  if (timeValue) {
    // construct a UTC date-time string to prevent timezone offset
    const datetime = new Date(`1970-01-01T${timeValue}Z`);
    const zonedTime = utcToZonedTime(datetime, "UTC");
    const formattedTime = format(zonedTime, "hh:mm a");
    return <span>{formattedTime}</span>;
  } else {
    return null;
  }
};

const PerformerField: FC<PerformerFieldProps> = ({ record }) => (
  <>
    {record && record.isPrimary && <CheckIcon />}
    {record && <Link to={`/performers/?filter=${JSON.stringify({
      id: [record.id],
    })}`}>{record.name}</Link>}
  </>
);

interface VenueFieldProps {
  record?: Record<string, any>;
  source?: string;
}
const VenueField: FC<VenueFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record && source && record[source] ? (
    <Link to={`/venues/?filter=${JSON.stringify({
      id: [record[source].id],
    })}`}>{record[source].name}</Link>
  ) : null;
};

const ViewInventory = () => {
  const record = useRecordContext();
  return (
    <Link
      to={{
        pathname: `/pos-inventory`,
        search: `filter=${JSON.stringify({
          productionId: [record.id],
        })}`,
      }}
      onClick={(event) => event.stopPropagation()}
    >
      View Inventory
    </Link>
  );
};



const productionFilter = [
  <TextInput source="id" />,
  <TextInput source="productionName" />,
  <BooleanInput source="isParking" />,
  <SelectInput source="productionStatus" label="Production Status" choices={productionStatusChoices} />,
  <NumberInput label="Total seats (min)" source="totalSeats_gte" />,
  <NumberInput label="Total seats (max)" source="totalSeats_lte" />,
  <ReferenceInput source="venueId" reference="venues">
    <AutocompleteInput optionText={choice => `${choice.id} - ${choice.name}`}  label="Venue" filterToQuery={venueFilterToQuery}/>
  </ReferenceInput>,
  <ReferenceArrayInput
    source="productionPerformers_in"
    reference="performers"
    label="Performers"
  >
    <AutocompleteArrayInput optionText={choice => `${choice.id} - ${choice.name}`} label="Performers" filterToQuery={performerFilterToQuery}/>
  </ReferenceArrayInput>,
  <DateInput label="Local date from" source="localDate_gte" />,
  <DateInput label="Local date to" source="localDate_lte" />,
];

export const ProductionList = () => (
  <List filters={productionFilter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="productionName" />
      <BooleanField source="isParking" />
      <FunctionField
        source="localDate"
        label="Local Date"
        render={(record: { localDate: string }) =>
        format(parseISO(record.localDate), "EEEE MMMM d, yyyy")}
      />
      <CustomTimeField source="localTime" />

      <DateField
        source="utcDateTime"
        label="UTC Date time"
        showTime
        options={{ timeZone: "UTC", timeZoneName: "short" }}
      />
      <TextField source="dateTimeStatus" />
      <TextField source="productionStatus" />
      <NumberField source="totalSeats" />

      <VenueField source="venue" />

      <ArrayField source="performers">
        <SingleFieldList>
          <PerformerField />
        </SingleFieldList>
      </ArrayField>
            <ViewInventory />
            <DateField source="createdDate" showTime />
            <DateField source="lastModifiedDate" showTime />
      <TextField source="lastModifiedBy" />
    </Datagrid>
  </List>
);
