import { FaWpforms } from "react-icons/fa";
import { NamespaceOverrideCreate } from "../components/Logging/NamespaceOverrideCreate";
import { NamespaceOverrideEdit } from "../components/Logging/NamespaceOverrideEdit";
import { NamespaceOverridesList } from "../components/Logging/NamespaceOverridesList";

const NamespaceOverrides = {
    name: "logging",
    icon: FaWpforms,
    list: NamespaceOverridesList,
    edit: NamespaceOverrideEdit,
    create: NamespaceOverrideCreate,
    menuLabel: "Overrides",
};

export default NamespaceOverrides;