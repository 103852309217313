import { FC } from "react";
import { Datagrid, List, TextField, TextInput, EditButton, useRecordContext, NumberField, DateField } from "react-admin";
import { ConfigurationValueTypes } from "../../utils/constants";
import { JsonField } from "react-admin-json-view";

const configurationsFilter = [
  <TextInput label="Key" source="key" />,
  <TextInput label="Value" source="value" />,
];


interface ValueFieldProps {
  record?: Record<string, any>;
  source?: any;
}
const ValueField: FC<ValueFieldProps> = ({ source }) => {
  const record = useRecordContext();
  switch (record.type) {
    case ConfigurationValueTypes.String:
      return <TextField source={source} label="Value" />
    case ConfigurationValueTypes.Number:  
      return <NumberField source={source} label="Value" />
    case ConfigurationValueTypes.Date:  
      return <DateField source={source} label="Value" />
    case ConfigurationValueTypes.Json:
      return <JsonField
      source={source}
      jsonString={true}
      label="Value"
      reactJsonOptions={{
        // Props passed to react-json-view
        name: null,
        collapsed: true,
        enableClipboard: false,
        displayDataTypes: false,
      }}
    />
    default:
      return <TextField source={source} label="Value" />
  }
};

export const ConfigurationList = () => (
  <List filters={configurationsFilter}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="key" label="Key" />
      <ValueField source="value"/>
      <TextField source="type" label="Type" />
      <TextField source="description" label="Description" />
      <EditButton />
    </Datagrid>
  </List>
);
