import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import { CircularProgress, Typography } from "@mui/material";
import {
    Button,
    useNotify,
    useDataProvider
} from 'react-admin';

import { SourceProductionMasterMapped } from "../../utils/constants";
import { FaParking, FaTicketAlt } from "react-icons/fa";

import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import { DateTimeDisplay } from "../../utils/CombinedDateTimeField";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Identifier } from "ra-core";


const ActionButton = (props: ActionButtonProps) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [mappingSuccessful, setMappingSuccessful] = useState(false);
    const { sourceKey, sourceType, productionMappingDetailsFormProps } = props;
    const { masterProductionId, isCrowdSource, crowdSourceId } = productionMappingDetailsFormProps;

    const handleMapClick = async (classification: string) => {
        try {

            const data: SourceProductionMasterMapped = {
                id: isCrowdSource ? parseInt(crowdSourceId!) : undefined,
                classification: classification,
                sourceProductionKey: sourceKey,
                masterProductionId: masterProductionId,
                sourceType: sourceType
            };
            if (isCrowdSource) {
                await dataProvider.mapCustomerProvidedMapping(data);
            } else {
                await dataProvider.create('source-production-mappings', { data: data });
            }
            notify('Mapping created successfully', { type: 'info' });
            setMappingSuccessful(true);
        } catch (error) {
            const err = error as Error;
            notify(`Error creating mapping: ${err.message}`, { type: 'error' });
        }
    };

    const disableMapButton = mappingSuccessful;

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>

            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick('Main')} style={{ minWidth: 'auto', padding: '6px', margin: '0' }}>
                <Tooltip title="Main">
                    <span style={{ display: 'inline-block', transform: 'rotate(90deg)' }}>
                        <FaTicketAlt />
                    </span>
                </Tooltip>
            </Button>


            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick('Parking')} style={{ minWidth: 'auto', padding: '6px', margin: '0' }}>
                <Tooltip title="Parking">
                    <span>
                        <FaParking />
                    </span>
                </Tooltip>
            </Button>


            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick('Both')} style={{ position: 'relative', minWidth: 'auto', padding: '16px', margin: '0', lineHeight: '0' }}>
                <Tooltip title="Both Main & Parking">
                    <span>
                        <span style={{ position: 'absolute', left: '0', top: '0', transform: 'translate(20%, 20%) rotate(90deg)' }}>
                            <FaTicketAlt />
                        </span>
                        <span style={{ position: 'absolute', left: '10%', top: '10%', transform: 'translate(60%, 60%)' }}>
                            <FaParking />
                        </span>
                    </span>
                </Tooltip>
            </Button>

        </div>
    );
};

interface ProductionMappingDetailsFormProps {
    masterProductionId: Identifier;
    localDate: string;
    classification: string;
    isMapped: boolean;
    primaryPerformer: string;
    venue: string;
    isCrowdSource?: boolean;
    sourceProductionKey?: string;
    sourceType?: string;
    crowdSourceId?: string;
}

interface ActionButtonProps {
    sourceKey: string;
    sourceType: string;
    productionMappingDetailsFormProps: ProductionMappingDetailsFormProps;
}


interface MasterProductionFilter {
    source_searchText_fts: string;
    source_localDate_gte: string;
    source_localDate_lte: string;
    source_masterProductionId: Identifier;
    sourceType?: string;
}

export const SourceProductionChild = (productionMappingDetailsFormProps: ProductionMappingDetailsFormProps) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [filter, setFilter] = useState<MasterProductionFilter>({
        'source_localDate_gte': productionMappingDetailsFormProps.localDate,
        'source_localDate_lte': productionMappingDetailsFormProps.localDate,
        'source_masterProductionId': productionMappingDetailsFormProps.masterProductionId,
        'source_searchText_fts': productionMappingDetailsFormProps.isCrowdSource ? productionMappingDetailsFormProps.sourceProductionKey! : ` ${productionMappingDetailsFormProps.primaryPerformer} ${productionMappingDetailsFormProps.venue}`,
        'sourceType': productionMappingDetailsFormProps.isCrowdSource ? productionMappingDetailsFormProps.sourceType : undefined
    });
    const fetchData = async (filter: any) => {
        setLoading(true);

        const sort = {
            field: 'localDate',
            order: 'DESC'
        };
        const pagination = {
            page: 1,
            perPage: 100
        };
        const respData: any = await dataProvider.getList('source-productions', { filter, sort, pagination });
        setData(respData.data);
        setLoading(false);
    }

    useEffect(() => {
        fetchData(filter);
    }, [filter]);

    const handleFilterChange = (filterName: string, value: any) => {
        const newFilter = { ...filter };
        switch (filterName) {
            case 'source_localDate_gte':
                newFilter['source_localDate_gte'] = value;
                break;
            case 'source_localDate_lte':
                newFilter['source_localDate_lte'] = value;
                break;
            case 'source_searchText_fts':
                newFilter['source_searchText_fts'] = value;
                break;
        }
        setFilter(newFilter);
    }

    const CustomRefreshButton = () => {
        const handleRefreshClick = () => {
            fetchData(filter);
        };

        return (
            <Button label="Refresh" onClick={handleRefreshClick}>
                <RefreshIcon />
            </Button>
        );
    };

    const renderFilters = () => {
        return (
            <div>
                <TextField
                    sx={{ marginRight: "1rem" }}
                    label="Search text"
                    value={filter.source_searchText_fts}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFilterChange('source_searchText_fts', event.target.value);
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        sx={{ marginRight: "1rem" }}
                        label="Date from"
                        value={parseISO(filter.source_localDate_gte)}
                        onChange={(newValue) => handleFilterChange('source_localDate_gte', format(newValue!, "yyyy-MM-dd"))}
                    />
                    <DatePicker
                        sx={{ marginRight: "1rem" }}
                        label="Date to"
                        value={parseISO(filter.source_localDate_lte)}
                        onChange={(newValue) => handleFilterChange('source_localDate_lte', format(newValue!, "yyyy-MM-dd"))}
                    />
                </LocalizationProvider>
            </div>
        );
    }


    return (
        <Card sx={{ margin: "1rem", padding: "1rem" }}>
            <CustomRefreshButton />
            {renderFilters()}
            {loading ? <CircularProgress />
                :
                data.length === 0 ? <Typography variant={"h3"} sx={{ marginTop: "1rem" }}>No results found</Typography>
                    :
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Actions</TableCell>
                                <TableCell align="right">Classification</TableCell>
                                <TableCell align="right">Source</TableCell>
                                <TableCell align="right">Production Name</TableCell>
                                <TableCell align="right">Production Date</TableCell>
                                <TableCell align="right">Venue</TableCell>
                                <TableCell align="right">Venue Location</TableCell>
                                <TableCell align="right">Production Status</TableCell>
                                <TableCell align="right">Production Key</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row: any) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <ActionButton
                                            sourceKey={row.sourceKey}
                                            sourceType={row.sourceType}
                                            productionMappingDetailsFormProps={productionMappingDetailsFormProps}
                                        />
                                    </TableCell>
                                    <TableCell align="right">{row.classification}</TableCell>
                                    <TableCell align="right">{row.sourceType}</TableCell>
                                    <TableCell align="right">{row.productionName}</TableCell>
                                    <TableCell align="right">{DateTimeDisplay({ sourceDate: row.localDate, sourceTime: row.localTime })}</TableCell>
                                    <TableCell align="right">{row.venueName}</TableCell>
                                    <TableCell align="right">{`${row.venueCity}, ${row.venueState}`}</TableCell>
                                    <TableCell align="right">{row.sourceProductionStatus}</TableCell>
                                    <TableCell align="right">{row.sourceKey}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            }


        </Card>
    );
};

