import {useDataProvider} from "react-admin";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React, {useEffect} from "react";
import TableBody from "@mui/material/TableBody";
import {CircularProgress, Typography} from "@mui/material";
import {DateTimeDisplay} from "../../utils/CombinedDateTimeField";

export const MasterProductionMappings = (props: any) => {
    
    
    const dataProvider = useDataProvider();
    const [mappings, setMappings] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const masterProduction = props.masterProduction;
    const {productionName, localDate, localTime, venueName, venueCity, venueState} = masterProduction;
    const masterProductionLine1 = `[${masterProduction.id}, ${masterProduction.classification}]`;
    const masterProductionLine2 = `${productionName} | ${DateTimeDisplay({ sourceDate: localDate, sourceTime: localTime })} | ${venueName} - ${venueCity}, ${venueState}`;
    useEffect(() => {
        const fetchMappings = async () => {
        const filter = {masterProductionId: masterProduction.id};
        const sort = {
            field: 'localDate',
            order: 'DESC'
        };
        const pagination = {
            page: 1,
            perPage: 100
        };
          var resp: any =  await dataProvider.getList('source-production-mappings', {filter, sort, pagination});
            setMappings(resp.data);
          setLoading(false);
        }
        fetchMappings();
        
    }, []);
    
    return (

        <Card sx={{margin: "0.2rem", padding: "0.5rem"}}>
            <Typography variant={"h6"} sx={{margin: "0.5rem"}}>Mappings</Typography>
            <Typography variant={"body1"} sx={{margin: "0.5rem"}}>{masterProductionLine1}</Typography>
            <Typography variant={"body1"} sx={{margin: "0.5rem"}}>{masterProductionLine2}</Typography>
            {loading ? <CircularProgress />
            :
                <>
                    {mappings && mappings.length > 0 ?
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Source Production Key</TableCell>
                                    <TableCell align="left">Source Production Type</TableCell>
                                    <TableCell align="left">Source Production Classification</TableCell>
                                    <TableCell align="left">Source Production Name</TableCell>
                                    <TableCell align="left">Source Production Date</TableCell>
                                    <TableCell align="left">Source Production Venue</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mappings.map((mapping: any) => (
                                    <TableRow key={mapping.id}>
                                        <TableCell align="left">{mapping.sourceProductionKey}</TableCell>
                                        <TableCell align="left">{mapping.sourceType}</TableCell>
                                        <TableCell align="left">{mapping.classification}</TableCell>
                                        <TableCell align="left">{mapping.sourceProduction.productionName}</TableCell>
                                        <TableCell align="left">{DateTimeDisplay({ sourceDate: mapping.sourceProduction.localDate, sourceTime: mapping.sourceProduction.localTime })}</TableCell>
                                        <TableCell align="left">{` ${mapping.sourceProduction.venueName},${mapping.sourceProduction.venueCity},${mapping.sourceProduction.venueState}`}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        :
                        <Typography variant={"body1"} sx={{margin: "1rem"}}>No Mappings Found</Typography>
                    }
                </>
            }
        </Card>
    );
};