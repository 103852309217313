import React from "react";
import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

export const RoleCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" required />
      <ReferenceArrayInput source="permissions" reference="permissions/org-permissions">
        <AutocompleteArrayInput
          optionText="name"
          fullWidth 
          isRequired         
        />
      </ReferenceArrayInput>
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Create>
);
