import {
  SimpleForm,
  TextInput,
  Create,
  ReferenceInput,
  SelectInput,
  minLength,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput
} from "react-admin";
import { memberStatusChoices } from "./common";
import { phoneNumberFormat } from "../../utils/constants";

import { email } from "ra-core";

export const MemberCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="email" validate={email()} />
      <TextInput
        source="phone"
        parse={phoneNumberFormat}
        validate={minLength(14)}
      />
      <SelectInput source="status" choices={memberStatusChoices} />
      <ReferenceInput source="organizationId" reference="organization-details" perPage={99999}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="roleIds" reference="roles">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput source="directoryId" />
      <TextInput source="objectId" />
      <TextInput source="userRole" defaultValue="EMPLOYEE" disabled />
      <BooleanInput source="isSupport" defaultValue={false} label="Is Support" />
      <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
    </SimpleForm>
  </Create>
);
