import { FaClock } from "react-icons/fa";
import { ScheduleEdit } from "../components/Scheduler/SchedulerEdit";

import { ScheduleList } from "../components/Scheduler/SchedulerList";

const Schedules = {
    name: "schedules",
    icon: FaClock,
    list: ScheduleList,
    edit: ScheduleEdit,
    //create: SettingCreate,
    menuLabel: "Scheduler",
};

export default Schedules;
