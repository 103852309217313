import {ExternalTenantTypes, MemberStatuses} from "../../utils/constants";

export const memberStatusChoices = [
  { id: MemberStatuses.Active, name: "Active" },
  { id: MemberStatuses.Inactive, name: "Inactive" },
  { id: MemberStatuses.Pending, name: "Pending" },
  { id: MemberStatuses.Deleted, name: "Deleted" },
];


export const externalTenantTypeChoices = [
  { id: ExternalTenantTypes.Skybox, name: ExternalTenantTypes.Skybox },
  { id: ExternalTenantTypes.None, name: ExternalTenantTypes.None}
];
