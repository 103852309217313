import React, { useState, useEffect } from "react";
import { useRecordContext } from "react-admin";
import { Link } from "react-router-dom";

interface Organization {
  id: string;
  name: string;
}

interface OrganizationReferenceFieldProps {
  organizations: Organization[];
  source: string;
}

const OrganizationReferenceField: React.FC<OrganizationReferenceFieldProps> = ({
  organizations,
  source
}) => {
  const record = useRecordContext();
  const organizationKey = record.organizationKey;
  const [organization, setOrganization] = useState<Organization | null>(null);

  useEffect(() => {
    console.log("loaded organization reference field");
    let organization = organizations.find(
      (org: any) => org.organizationKey === organizationKey
    );
    if (organization) {
      setOrganization(organization);
    }
  }, [organizationKey, organizations]);

  return organization ? (
    <Link to={`/organizations/${organization.id}`}>{organization.name}</Link>
  ) : (
    <span>Loading...</span>
  );
};

export default OrganizationReferenceField;
