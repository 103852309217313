import { FieldProps, useRecordContext } from 'react-admin';
import { Chip, Tooltip } from '@mui/material';

interface FormattedMarketComparablesFieldProps extends FieldProps {
}
const styles = {
    fontSize: '0.55rem',
    backgroundColor: 'lightblue',
    padding: '0',
    margin: '0.05rem',
    borderRadius: '2px',
    fontWeight:'bold'
};
export const FormattedMarketComparablesField = (props: FormattedMarketComparablesFieldProps) => {
    const record = useRecordContext(props);

    const sections = record.marketGroupCriteria?.sections || null;
    const splits = record.marketGroupCriteria?.validSplitsIncludeV2 || null;
    const attributesExclude = record.marketGroupCriteria?.attributesExclude || null;
    const rowRange = record.marketGroupCriteria?.rowFrom ? `${record.marketGroupCriteria?.rowFrom}-${record.marketGroupCriteria?.rowTo}` : null;
    const lowerOutlierMethodDisplay = record.marketGroupCriteria?.lowerOutlierMethod ? `EXCLUDE OUTLIERS METHOD: ${record.marketGroupCriteria?.lowerOutlierMethod.toUpperCase()}` : null;
    const lowerOutlierAmountDisplay = record.marketGroupCriteria?.lowerOutlierAmount ? `EXCLUDE OUTLIERS: ${record.marketGroupCriteria?.lowerOutlierAmount}` : null;

    const sectionDisplay = sections && sections?.length > 0 ? (sections as any[]).map(x => x.sectionName).join(', ') : null;
    const rowDisplay = rowRange ? `ROW RANGE: ${rowRange}` : null;
    const splitDisplays = splits && splits?.length > 0 ? `SPLITS: ${splits.join(', ')}` : null;
    const attibutesDisplay = attributesExclude && attributesExclude.length > 0 ? `EXCLUDE ATTRIBUTES: ${attributesExclude.join(', ')}` : null;


    return (
        <>
            <div >
                {sectionDisplay &&

                    <Tooltip title={sectionDisplay} >
                        <Chip  sx={styles} size='small' label={'SECTIONS'} variant="outlined"  />
                    </Tooltip>

                }
                {attibutesDisplay &&
                    <Tooltip title={attibutesDisplay}>
                        <Chip  style={styles} size='small' label={'EXCLUDE ATTRIBUTES'} variant="outlined"></Chip>
                    </Tooltip>
                }

                {rowDisplay &&

                    <Chip  style={styles} size='small' label={rowDisplay} variant="outlined"></Chip>
                }
            </div>
            <div>
                {splitDisplays &&
                    <Tooltip title={splitDisplays}>
                    <Chip  style={styles} size='small' label={splitDisplays} variant="outlined" />
                    </Tooltip>
                }
                {lowerOutlierAmountDisplay &&

                    <Chip  style={styles} size='small' label={lowerOutlierAmountDisplay} variant="outlined"></Chip>}
                {lowerOutlierMethodDisplay &&

                    <Chip  style={styles} size='small' label={lowerOutlierMethodDisplay} variant="outlined"></Chip>}
            </div>
        </>
    );
};