import React from 'react';
import {
    Button, useListContext, useUpdateMany,
    useNotify,
    useRefresh,
    useUnselectAll
} from 'react-admin';
import {FaBan, FaFlask, FaPlay} from 'react-icons/fa';
import {PricingMode} from "./common";

const BulkUpdateToolbar = () => {
    const {selectedIds} = useListContext();
    const [updateMany] = useUpdateMany();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('organization-pricing-setting');

    const handleBulkUpdate = (pricingMode: PricingMode) => {
        updateMany(
            'organization-pricing-setting',
            {ids: selectedIds, data: {pricingMode: pricingMode}},
            {
                onSuccess: () => {
                    refresh();
                    unselectAll();
                },
            }
        )
            .then(r => {
                notify('Organizations updated successfully');
                refresh();
                unselectAll();
        })
            .catch((error) => {
                notify('Error: organizations not updated', { type: 'warning' });
            });
    };

    return (
        <>
        <Button
            label="Enable"
            onClick={() => handleBulkUpdate(PricingMode.Enabled)}
            startIcon={<FaPlay/>}
        />
        <Button
            label="Simulation Only"
            onClick={() => handleBulkUpdate(PricingMode.SimulationOnly)}
            startIcon={<FaFlask/>}
        />
        <Button
            label="Disabled"
            onClick={() => handleBulkUpdate(PricingMode.Disabled)}
            startIcon={<FaBan/>}
        />
        </>
    );
};

export default BulkUpdateToolbar;
