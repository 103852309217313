import React from "react";
import {
  Datagrid,
  List,
  TextField,
  TextInput,
  EditButton,
  FunctionField,
  SingleFieldList,
  ArrayField,
} from "react-admin";
import { format } from "date-fns";

const roleFilter = [<TextInput label="Name" source="name" />];

export const RoleList = () => (
  <List filters={roleFilter} >
    <Datagrid  bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="key" />
      <ArrayField  source="permissions">
        <SingleFieldList linkType={false} sx={{display:"grid"}}>
          <TextField source="name"  />
        </SingleFieldList>
      </ArrayField>
      <FunctionField
        label="Last Updated"
        render={(record: { lastModifiedOn: any; lastModifiedBy: any }) =>
          `${format(
            new Date(record.lastModifiedOn),
            "MMMM do yyyy, h:mm:ss a"
          )} by ${record.lastModifiedBy}`
        }
      />
      <EditButton />
    </Datagrid>
  </List>
);
