import React from 'react';
import {Link} from 'react-router-dom';
import {useRecordContext} from 'react-admin';

const OrganizationLink = ({ children, source } : any) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Link to={`/organizations/${record.id}`} style={{ textDecoration: 'none' }}>
            {children}
        </Link>
    );
};

export default OrganizationLink;



