export type OrganizationEntry = {
    id?: number; 
    name: string;
    abbreviation: string;
    isValid?: boolean;
    isStored?: boolean;
    validDate?: string;
    sourceType?: string;
    tenantType?: string; 
    pricingMode?: PricingMode; 
};

export enum PricingMode {
    Enabled = "Enabled",
    SimulationOnly = "SimulationOnly",
    Disabled = "Disabled"
}

export const PricingModeChoices = [
    { id: PricingMode.Enabled, name: "Enabled" },
    { id: PricingMode.SimulationOnly, name: "Simulation Only" },
    { id: PricingMode.Disabled, name: "Disabled" }
];

export enum TenantType {
    VSSkybox = "VSSkybox",
    PricerQx = "None"
}

export const TenantTypeChoices = [
    { id: TenantType.VSSkybox, name: "VSSkybox" },
    { id: TenantType.PricerQx, name: "PricerQx" }
];