import { useDataProvider, useNotify, useRecordContext } from "react-admin";
import IconButton from "@mui/material/IconButton";
import RejectIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import React, { useState } from "react";

const CrowdSourceMappingRejectButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [isRejected, setIsRejected] = useState(!(record.crowdSourceMappingStatus == 'Pending'))
    const handleDelete = async () => {

        try {
            await dataProvider.rejectCustomerProvidedMapping(record.id,
                {
                    id: record.id,
                    masterProductionId: record.masterProductionId,
                    sourceProductionKey: record.sourceKey,
                    sourceType: record.sourceType,
                    classification: record.classification
                });
            setIsRejected(true);
            notify(`Mapping Rejected succesfully`, { type: "success" });
        }
        catch (error: any) {
            notify(error.body[0].errorMessage || 'An unknown error occurred', { type: "error" });
        }
    }
    return <IconButton aria-label="Reject" onClick={handleDelete} disabled={isRejected}>
        <Tooltip title="Reject Crowd Source Mapping" placement="right">
            <RejectIcon color={isRejected ? "disabled" : "error"} />
        </Tooltip>
    </IconButton>
}
export default CrowdSourceMappingRejectButton;