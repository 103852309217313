import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  minLength,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  useNotify
} from "react-admin";
import { memberStatusChoices } from "./common";
import { phoneNumberFormat } from "../../utils/constants";
import { email } from "ra-core";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

const MemberEdit = () => {

  const notify = useNotify();

  const onError = (error: any) => {
    console.log(JSON.stringify(error));
    notify(`${error.body.map(function (item: any) {
      return item.message;
    }).join(",")}`);
  };

  return (
    <Edit mutationMode="pessimistic" mutationOptions={{ onError }}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" />
        <TextInput source="email" validate={email()} />
        <TextInput source="username" disabled />
        <TextInput
          source="phone"
          parse={phoneNumberFormat}
          validate={minLength(14)}
        />
        <SelectInput source="status" choices={memberStatusChoices} />
        <ReferenceInput source="organizationId"  reference="organization-details" perPage={99999}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput source="roleIds" reference="roles">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="objectId" label="AAD ID" disabled />
        <BooleanInput source="isSupport" label="Is Support User" />
        <BooleanInput source="isSkyboxUser" label="Is Skybox User" disabled />
        <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
      </SimpleForm>
    </Edit>
  )
};

export default MemberEdit;
