import { MenuItemLink } from "react-admin";
import { IconType } from "react-icons/lib";

type ResourceLinkProps = {
  icon: IconType;
  menuLabel: string;
  name: string;
};

const ResourceMenuLink = ({
  resource,
  dense = false,
}: {
  resource: ResourceLinkProps;
  dense: boolean;
}) => {
  return (
    <MenuItemLink
      to={resource.name}
      state={{ _scrollToTop: true }}
      primaryText={resource.menuLabel}
      leftIcon={<resource.icon />}
      dense={dense}
    />
  );
};

export default ResourceMenuLink;
