import {
    Datagrid,
    List,
    TextField,
    Pagination, SearchInput, DateInput, useRecordContext, SelectInput, TextInput
} from "react-admin";
import React from "react";
import { format} from "date-fns";
import {SourceProductionMappingMasterProduction} from "./MasterProduction";
import {Button} from "@mui/material";
import Drawer from '@mui/material/Drawer';
import {SourceProductionMappingAudits} from "./Audits";
import {CombinedDateTimeField} from "../../utils/CombinedDateTimeField";
import {
    comparableMarketplaceSourceChoices,
    ProductionClassifications
} from "../../utils/constants";


export const classificationChoices = [
    { id: ProductionClassifications.Main, name: ProductionClassifications.Main },
    { id: ProductionClassifications.Parking, name: ProductionClassifications.Parking}
];


const filters = [
    <SearchInput
        source="searchText_fts"
        alwaysOn/>,
    <DateInput
        label="Source Production Date From"
        source="localDate_gte"
        alwaysOn/>,
    <DateInput
        label="Source Production Date To"
        source="localDate_lte"
        alwaysOn/>,
    <TextInput
        label="Source Production Key"
        source="sourceProductionKey"/>,
    <SelectInput
        label="Source Type"
        source="sourceType"
        choices={comparableMarketplaceSourceChoices}
        required
    />,
    <SelectInput
        source="classification"
        choices={classificationChoices}
        required
    />
];

export const SourceProductionMappingList = () => {
    const [open, setOpen] = React.useState(false);
    const [selectedRecord, setSelectedRecord] = React.useState({} as any);


    const ViewAudits = () => {
        const record = useRecordContext();
        const handleAuditClick = () => {
            
            setSelectedRecord(record);
            setOpen(true);
        };
        return (
            <Button onClick={() => handleAuditClick()}>View Record</Button>
        );
    };
    
    return (
        <>
            <List
                filters={filters}
                filterDefaultValues={
                    {
                        localDate_gte: format(new Date(), 'yyyy-MM-dd'),
                        localDate_lte: format(new Date(), 'yyyy-MM-dd'),
                    }
                }
                title="Production Mapping Review"
                pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
                perPage={25}
                sort={{ field: 'sourceproductionlocaldate', order: 'DESC' }}
            >

                <Datagrid
                    bulkActionButtons={false}
                    expand={SourceProductionMappingMasterProduction}
                >
                    <TextField source="id" />
                    <TextField source="sourceProductionKey" label="Source Production Key" />
                    <TextField source="sourceType" label="Source Type" />
                    <TextField source="sourceProduction.productionName" label="Source Production Name" />
                    <CombinedDateTimeField sourceDate="sourceProduction.localDate" sourceTime="sourceProduction.localTime" isComplexObject={true} label={"Production Date and Time"}/>
                    <TextField source="sourceProduction.localDate" label="Source Production Date" />
                    <TextField source="sourceProduction.localTime" label="Source Production Time" />
                    <TextField source="sourceProduction.venueName" label="Source Production Venue" />
                    <TextField source="sourceProduction.venueCity" label="Source Production Venue Location" />
                    <TextField source="sourceProduction.classification" label="Source Production Classification" />
                    
                    <ViewAudits />
                </Datagrid>
            </List>
            <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"} PaperProps={{
                sx: { width: "50%" },
            }}>
                <SourceProductionMappingAudits record={selectedRecord}/>
            </Drawer>
        </>
  
)};
