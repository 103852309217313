import { FaHistory } from "react-icons/fa";
import { OrganizationPricingSettingAuditList } from "../components/Audits/OrganizationPricingSettingAuditList";

const OrganizationPricingSettingAudits = {
  name: "organization-pricing-setting-audits",
  icon: FaHistory,
  list: OrganizationPricingSettingAuditList,
  menuLabel: "Org Pricing Setting",
};

export default OrganizationPricingSettingAudits;
