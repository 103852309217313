import {
  Datagrid,
  List,
  TextField,
  TextInput,
  DateInput,
  BooleanField,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  useRecordContext,
  useDataProvider,
  DateTimeInput,
} from "react-admin";
import { seatTypeChoices, sourceTypeChoices, stockTypeChoices } from "../Organizations/common";
import { CustomOrganizationReferenceInput } from "../Organizations/CustomOrganizationReferenceInput";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrganizationReferenceField from "../Organizations/OrganizationReferenceField";
import { inventoryStatusChoices } from "./common";
import { formatDateWithoutTZ, parseDateToUTC, prodFilterToQuery } from "../../utils/helpers";
interface ProductionFieldProps {
  record?: Record<string, any>;
  source?: string;
}
const ProductionField: FC<ProductionFieldProps> = ({ source }) => {
  const record = useRecordContext();
  return record && source && record[source] ? (
    <Link to={`/productions/?filter=${JSON.stringify({
      id: [record[source].id],
    })}`}>
      {`${record[source].id} - ${record[source].productionName}`}
    </Link>
  ) : null;
};


const inventoryFilter = [
  <TextInput source="id" />,
  <CustomOrganizationReferenceInput
    source="organizationKey"
    label="Organization"
  />,
  <ReferenceInput source="productionId" reference="productions" label="Production">
    <AutocompleteInput  optionText={choice => `${choice.id} - ${choice.productionName}`} filterToQuery={prodFilterToQuery} label="Production (Name or ID)"/>
  </ReferenceInput>,

  <TextInput source="section" />,
  <TextInput source="row" />,
  <NumberInput source="quantity_gte" label="Min Quantity" />,
  <NumberInput source="quantity_lte" label="Max Quantity" />,
  <TextInput source="startSeat" />,
  <TextInput source="endSeat" />,
  <SelectInput source="stockType" choices={stockTypeChoices} />,
  <SelectInput source="seatType" choices={seatTypeChoices} />,
  <SelectInput source="sourceType" choices={sourceTypeChoices} />,
  <NumberInput source="costPrice_gte" label="Min Cost Price" />,
  <NumberInput source="costPrice_lte" label="Max Cost Price" />,
  <NumberInput source="listPrice_gte" label="Min List Price" />,
  <NumberInput source="listPrice_lte" label="Max List Price" />,
  <NumberInput source="soldPrice_gte" label="Min Sold Price" />,
  <NumberInput source="soldPrice_lte" label="Max Sold Price" />,
  <BooleanInput source="isInHand" />,
  <BooleanInput source="isParking" />,
  <BooleanInput source="isShared" />,
  <DateInput label="In Hand Date from" source="inHandDate_gte" />,
  <DateInput label="In Hand Date to" source="inHandDate_lte" />,
  <SelectInput source="inventoryStatus" choices={inventoryStatusChoices} />,
   <DateTimeInput
    label="Created Date from"
    source="createdDate_gte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <DateTimeInput
    label="Created Date to"
    source="createdDate_lte"
    format={formatDateWithoutTZ}
    parse={parseDateToUTC}
  />,
  <TextInput source="lastModifiedBy" />,
  <DateTimeInput
  label="Last Modified Date from"
  source="lastModifiedDate_gte"
  format={formatDateWithoutTZ}
  parse={parseDateToUTC}
/>,
<DateTimeInput
  label="Last Modified Date to"
  source="lastModifiedDate_lte"
  format={formatDateWithoutTZ}
  parse={parseDateToUTC}
/>,

];

export const InventoryList = () => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const dataProvider = useDataProvider();
  useEffect(() => {
    dataProvider
      .getList("organizations", {
        pagination: { page: 1, perPage: 500 },
        sort: { field: "name", order: "ASC" },
        filter: {},
      })
      .then(({ data }) => {
        setOrganizations(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dataProvider]);

  return (
    <List filters={inventoryFilter}>
      <Datagrid>
        <TextField source="id" />
        <OrganizationReferenceField organizations={organizations} source="Organization"/>
        <ProductionField source="production" />
        <TextField source="section" />
        <TextField source="row" />
        <TextField source="quantity" />
        <TextField source="startSeat" />
        <TextField source="endSeat" />
        <TextField source="stockType" />
        <TextField source="seatType" />
        <TextField source="costPrice" />
        <TextField source="listPrice" />
        <TextField source="soldPrice" />
        <TextField source="splitRule" />
        <BooleanField source="isInHand" />
        <BooleanField source="isParking" />
        <BooleanField source="isShared" />
        <TextField source="inventoryStatus" />
        <TextField source="validSplits" />
        <TextField source="externalNotes" />
        <TextField source="sourceKey" />
        <TextField source="sourceProductionKey" />
        <TextField source="sourceType" />
        <TextField source="inHandDate" />
        <TextField source="internalNotes" />
        <TextField source="attributes" />
        <TextField source="createdDate" />
        <TextField source="lastModifiedBy" />
        <TextField source="lastModifiedDate" />
      </Datagrid>
    </List>
  );
};
