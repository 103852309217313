import Button from "@mui/material/Button";
import Cancel from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router";

export const CancelButton = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Button startIcon={<Cancel />} color="primary" onClick={handleClick}>
      Cancel
    </Button>
  );
};
