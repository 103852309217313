import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {CircularProgress, Typography} from "@mui/material";
import {
    Button,
    useNotify,
    useDataProvider, HttpError
} from 'react-admin';

import {SourcePerformerMasterMapped} from "../../utils/constants";
import {FaTicketAlt} from "react-icons/fa";

import {Tooltip} from "@mui/material";
import Card from "@mui/material/Card";
import RefreshIcon from '@mui/icons-material/Refresh';
import PerformerMappingRemoveButton from "./PerformerMappingRemoveButton";


const ActionButton = (props: ActionButtonProps) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [mappingSuccessful, setMappingSuccessful] = useState(false);
    const {id, performerMappingDetailsFormProps} = props;
    const [mappedMasterPerformerId, setMappedMasterPerformerId] = useState(performerMappingDetailsFormProps.mappedMasterPerformerId);
    const handleMapClick = async () => {
        try {

            const data: SourcePerformerMasterMapped = {
                sourcePerformerKey: performerMappingDetailsFormProps.sourcePerformerKey,
                masterPerformerId: id,
                sourceType: performerMappingDetailsFormProps.sourceType
            };
            await dataProvider.create('source-performer-mappings', {data: data});
            notify('Mapping created successfully', {type: 'info'});
            setMappingSuccessful(true);
            setMappedMasterPerformerId(id);
        } catch (error) {
            const err = error as HttpError;
            notify(`Error creating mapping: ${err.body[0]?.errorMessage}`, {type: 'error'});
        }
    };

    const disableMapButton = mappingSuccessful || mappedMasterPerformerId === id;

    return (
        <div style={{display: 'inline-flex', alignItems: 'left'}}>

            <Button
                disabled={disableMapButton}
                onClick={() => handleMapClick()} style={{minWidth: 'auto', padding: '6px', margin: '0'}}>
                <Tooltip title="Apply Mapping">
                    <span style={{display: 'inline-block', transform: 'rotate(90deg)'}}>
                    <FaTicketAlt/>
                  </span>
                </Tooltip>
            </Button>

            {
                disableMapButton &&
                <PerformerMappingRemoveButton 
                    setMappingSuccessful={setMappingSuccessful}
                    setMappedMasterPerformerId={setMappedMasterPerformerId}
                />
            }
            

        </div>
    );
};

interface PerformerMappingDetailsFormProps {
    sourcePerformerKey: string;
    sourceType: string;
    name: string;
    isMapped: boolean;
    mappedMasterPerformerId?: number;
    refresh():  void;
}

interface ActionButtonProps {
    id: number;
    performerMappingDetailsFormProps: PerformerMappingDetailsFormProps;
}

interface MasterPerformerFilter {
  name: string;
}

export const MasterPerformerChild = (performerMappingDetailsFormProps: PerformerMappingDetailsFormProps) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const [filter, setFilter] = useState<MasterPerformerFilter>({
        name: performerMappingDetailsFormProps.name
    });
    const fetchData = async (filter: any) => {
        setLoading(true);
        
        const sort = {
            field: 'name',
            order: 'ASC'
        };
        const pagination = {
            page: 1,
            perPage: 100
        };
        
        const respData: any = await dataProvider.getList('master-performer', {filter, sort, pagination});
        setData(respData.data);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchData(filter);
    }, [filter]);
    
    const handleFilterChange = (filterName: string, value: any) => {
        const newFilter = {...filter};
        switch (filterName) {
            case 'name':
                newFilter['name'] = value;
                break;
        }
        setFilter(newFilter);
    }

    const CustomRefreshButton = () => {
        const handleRefreshClick = () => {
            fetchData(filter);
            performerMappingDetailsFormProps.refresh();
        };

        return (
            <Button label="Refresh" onClick={handleRefreshClick}>
                <RefreshIcon />
            </Button>
        );
    };
    
    const renderFilters = () => {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextField
                    sx={{marginRight: "1rem"}}
                    label="Name"
                    value={filter.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFilterChange('name', event.target.value);
                    }}
                />
            </LocalizationProvider>
        </div>
    );
    }
    

    return (
        <Card sx={{margin: "1rem", padding: "1rem"}}>
            <CustomRefreshButton/>
            {renderFilters()}
            {loading ? <CircularProgress />
                :
                data.length === 0 ? <Typography variant={"h3"} sx={{marginTop: "1rem"}}>No results found</Typography>
                    :
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Actions</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Master Performer Id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row: any) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <ActionButton id={row.id} performerMappingDetailsFormProps={performerMappingDetailsFormProps} />
                                </TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.id}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            }
        </Card>
    );
};

