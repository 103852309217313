import { useDataProvider, useNotify, useRecordContext} from "react-admin";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";

const SectionMappingDeleteButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const handleDelete = async () => {
        var data = {
            masterVenueId: record.masterVenueId,
            sectionName: record.sectionName,
        }
        try {
            await dataProvider.deleteSectionPriorityMapping({data});
            notify(`Mapping Deleted`, { type: "success" });
        }
        catch (error: any) {
            notify(error.body[0].errorMessage || 'An unknown error occurred', { type: "error" });
        }
    }
    return <IconButton aria-label="delete" onClick={handleDelete}>
        <DeleteIcon color="error"/>
    </IconButton>
}
export default SectionMappingDeleteButton;