import { FaHistory } from "react-icons/fa";
import { ChangedPasswordAuditList } from "../components/Audits/ChangedPasswordAuditList";

const ChangedPasswordAudits = {
    name: "changed-password-audits",
    icon: FaHistory,
    list: ChangedPasswordAuditList,
    menuLabel: "Changed Passwords",
};

export default ChangedPasswordAudits;
