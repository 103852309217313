import { FaUserTie } from "react-icons/fa";
import { RoleCreate } from "../components/Roles/RoleCreate";
import { RoleEdit } from "../components/Roles/RoleEdit";
import { RoleList } from "../components/Roles/RoleList";

const Roles = {
  name: "roles",
  icon: FaUserTie,
  list: RoleList,
  edit: RoleEdit,
  create: RoleCreate,
  menuLabel: "Roles",
};

export default Roles;
