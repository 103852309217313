import {useDataProvider} from "react-admin";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import React, {useEffect} from "react";
import TableBody from "@mui/material/TableBody";
import {CircularProgress, Typography} from "@mui/material";


export const SourceVenueMappingAudits = (recordContainer:any) => {
    
    
    const dataProvider = useDataProvider();
    const [audits, setAudits] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const record = recordContainer.record;  
    
    const {name, city, state, address, postalCode, sourceKey, sourceType} = record;
    const sourceVenueLine1 = `[${sourceKey}, ${sourceType}]`;
    const sourceVenueLine2 = `${name} | ${address} - ${city}, ${state} ${postalCode}`;
    useEffect(() => {
        console.log("record", record)
        const fetchAudits = async () => {
            const data = {
                SourceVenueKey: record.sourceKey,
                SourceType: record.sourceType
            }
          var resp =  await dataProvider.getSourceVenueMappingAudits(data);
          setAudits(resp);
          setLoading(false);
        }
        fetchAudits();
        
    }, []);
    
    return (

        <Card sx={{margin: "0.2rem", padding: "0.5rem"}}>
            <Typography variant={"h6"} sx={{margin: "0.5rem"}}>Audits</Typography>
            <Typography variant={"body1"} sx={{margin: "0.5rem"}}>{sourceVenueLine1}</Typography>
            <Typography variant={"body1"} sx={{margin: "0.5rem"}}>{sourceVenueLine2}</Typography>
            {loading ? <CircularProgress />
            :
                <>
                    {audits && audits.length > 0 ?
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Master Venue ID</TableCell>
                                    <TableCell align="left">Master Venue Name</TableCell>
                                    <TableCell align="left">Master Venue Address</TableCell>
                                    <TableCell align="left">Master Venue Location</TableCell>
                                    <TableCell align="left">Mapped By</TableCell>
                                    <TableCell align="left">Mapping Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {audits.map((audit: any) => (
                                    <TableRow key={audit.id}>
                                        <TableCell align="left">{audit.masterVenueId == 0 ? "REMOVED": audit.masterVenueId}</TableCell>
                                        <TableCell align="left">{audit.masterVenue ? audit.masterVenue.name : ""}</TableCell>
                                        <TableCell align="left">{audit.masterVenue ? audit.masterVenue.address : ""}</TableCell>
                                        <TableCell align="left">{audit.masterVenue ? `${audit.masterVenue?.city}, ${audit.masterVenue?.state} ${audit.masterVenue?.postalCode}` : "" }</TableCell>
                                        <TableCell align="left">{audit.createdBy}</TableCell>
                                        <TableCell align="left">{audit.createdDate}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        :
                        <Typography variant={"body1"} sx={{margin: "1rem"}}>No Audits found</Typography>
                    }
                </>
            }
        </Card>
    );
};