import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    NumberInput,
} from "react-admin";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const ProductionMappingsEdit = (props: any) => {
    return (
        <Edit mutationMode="undoable"  >
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput source="MappingKey" value="MappingKey" disabled />
                <TextInput source="name" disabled/>
                <TextInput source="requestingService" disabled></TextInput>
                <TextInput source="MappingType" disabled></TextInput>
                <TextInput source="description" required />
                <TextInput source="defaultMappingTime" required helperText="Valid Cron Expression" disabled/>
                <BooleanInput source="allowManualExecution"  disabled/>
                <BooleanInput source="allowExecutionOverlap"  disabled/>
                <TextInput source="lifetime" disabled/>
                <TextInput source="targetTopicName" disabled />
                <NumberInput source="versionNumber" disabled/>
                <TextInput source="lastModifiedBy" defaultValue="SYSTEM" disabled />
            </SimpleForm>
        </Edit>
    )};