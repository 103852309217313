import { FaFileInvoiceDollar } from "react-icons/fa";
import { PricingTemplatesList } from "../components/PricingTemplates/PricingTemplateList";


const PricingTemplates = {
  name: "pricing-templates",
  icon: FaFileInvoiceDollar,
  list: PricingTemplatesList,
  // create: PricingGroupCreate,
  //edit: PricingGroupEdit,
  menuLabel: "Pricing Templates",
};

export default PricingTemplates;