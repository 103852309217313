import "bootstrap/dist/css/bootstrap.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const baseUrl =
  document.getElementsByTagName("base")[0]?.getAttribute("href") || "";
const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);
const msalInstance = new PublicClientApplication(msalConfig);

console.log("1. Environment: ", process.env);
console.log("2. msalConfig: ", msalConfig);

root.render(
  <BrowserRouter basename={baseUrl}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </BrowserRouter>
);
