import { FaCalendarAlt } from "react-icons/fa";
import { ActiveMarketEventList } from "../components/ActiveMarketEvents/ActiveMarketEventList";

const ActiveMarketEvents = {
  name: "activemarketevents",
  icon: FaCalendarAlt,
  list: ActiveMarketEventList,
  menuLabel: "Active Market Events",
};

export default ActiveMarketEvents;
