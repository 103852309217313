import { Datagrid, EditButton, List, TextField } from "react-admin";

export const LoggingDefaultList = () => {
    return (
        <List>
            <Datagrid >
                <TextField source="type" label="ServiceKey" sortable={false } />
                <TextField source="value" label="Log Level" />
                <EditButton/>
            </Datagrid>
        </List>
    );
}