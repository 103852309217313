import { Datagrid, List, TextField, TextInput, EditButton } from "react-admin";

const settingsFilter = [
  <TextInput label="Key" source="id" />,
  <TextInput label="Value" source="value" />,
];

export const SettingList = () => (
  <List filters={settingsFilter}>
    <Datagrid>
      <TextField source="id" label="Key" />
      <TextField source="value" label="Value" />
      <EditButton />
    </Datagrid>
  </List>
);
