import { FaHistory } from "react-icons/fa";
import { PricerOrganizationInventoryAuditList } from "../components/Audits/PricerOrganizationInventoryAuditList";

const PricerOrganizationInventoryAudits = {
  name: "pricer-organization-inventory-audits",
  icon: FaHistory,
  list: PricerOrganizationInventoryAuditList,
  menuLabel: "Pricer Org Inventory",
};

export default PricerOrganizationInventoryAudits;
