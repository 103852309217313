import MyAdmin from "./admin";
import { useIsAuthenticated } from "@azure/msal-react";
import SignInPage from "./components/auth/SignInPage";
import { appInsights } from "./appinsights";

const App = () => {
  try {
    appInsights.loadAppInsights();
  } catch (e) {
    console.log("No telemetry enabled...");
  }
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <MyAdmin /> : <SignInPage />;
};

export default App;
