import { SaveButton, Toolbar } from "react-admin";
import { CancelButton } from '../../components/CustomToolbar/CancelButton'

export const EditNoDeleteToolbar = () => {
    return (
        <Toolbar>
          <div>
            <SaveButton />
            <CancelButton />
          </div> 
        </Toolbar>
    );
};