import React, { useEffect, useState } from 'react';
import { Edit, SimpleForm, SelectInput, TextInput, useDataProvider } from 'react-admin';
import { EditToolbar } from '../CustomToolbar/EditToolbar';
import { externalTenantTypeChoices } from '../Organizations/common';
import {StripePrice, StripeProduct} from "../../utils/customDataProvider";

export const StripeConfigurationEdit = () => {
    const dataProvider = useDataProvider();
    const [products, setProducts] = useState<{ id: string, name: string }[]>([]);
    const [prices, setPrices] = useState<{ id: string, name: string }[]>([]);
    const [selectedProductId, setSelectedProductId] = useState<string>('');

    useEffect(() => {
        dataProvider.getStripeProducts()
            .then((response: StripeProduct[]) => {
                setProducts(response.map(product => ({
                    id: product.id,
                    name: product.name
                })));
                if (response.length > 0) {
                    setSelectedProductId(response[0].id);
                }
            });
    }, [dataProvider]);

    useEffect(() => {
        if (selectedProductId) {
            dataProvider.getStripePrices(selectedProductId)
                .then((response: StripePrice[]) => {
                    setPrices(response.map(price => ({
                        id: price.id,
                        name: price.name
                    })));
                });
        }
    }, [selectedProductId, dataProvider]);

    return (
        <Edit mutationMode="pessimistic">
            <SimpleForm toolbar={<EditToolbar />}>
                <TextInput source="configKey" disabled />
                <SelectInput
                    source="productId"
                    choices={products}
                />
                <SelectInput
                    source="priceId"
                    choices={prices}
                />
                <SelectInput
                    source="defaultOrganizationType"
                    choices={externalTenantTypeChoices}
                    required
                />
            </SimpleForm>
        </Edit>
    );
};
