import {
  Datagrid,
  List,
  TextField,
  DateField,
  TextInput,
} from "react-admin";
import { ShowAuditEventData } from "./ShowAuditEventData";

const auditsFilter = [
  <TextInput source="sendToEmail" label="SendToEmail" />,
  <TextInput source="correlationId" label="CorrelationId" />,
];

export const ResetPasswordAuditList = () => (
  <List filters={auditsFilter} sort={{ field: "ts", order: "DESC" }}>
    <Datagrid bulkActionButtons={false} expand={ShowAuditEventData}>
      <DateField
        source="ts"
        label="DateTime"
        showTime
        options={{ timeZone: "UTC", timeZoneName: "short" }}
      />
      <TextField source="sendToEmail" label="SendToEmail" />
      <TextField source="auditOrganizationKey" label="AuditOrganizationKey" />
      <TextField source="auditUsername" label="AuditUsername" />
      <TextField source="id" label="Id" sortable={false} />
      <TextField source="correlationId" label="CorrelationId" sortable={false} />
    </Datagrid>
  </List>
);
