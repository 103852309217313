import { LogTypes } from "../../utils/constants";

export const logTypeChoices = [
    { id: LogTypes.Verbose, name: "Verbose" },
    { id: LogTypes.Debug, name: "Debug" },
    { id: LogTypes.Error, name: "Error" },
    { id: LogTypes.Information, name: "Information" },
    { id: LogTypes.Warning, name: "Warning" },
    { id: LogTypes.Fatal, name: "Fatal" }
]

export interface OverrideTypes {
    settingKey: string,
    type: string
}

//export const validateNamespace = (message:any) => {
//    var regex = new RegExp("^[a-zA-Z_]\w*(\.[a-zA-Z_]\w*)*$");
//    return regex.test(message);
//}
export const validateNamespace = (namespace: string) => {

    if (!namespace || !namespace.length) {
        return false;
    } else {
        var regex = new RegExp(/^[a-zA-Z_][a-zA-Z0-9_]*(\.[a-zA-Z_][a-zA-Z0-9_]*)*$/);
        return regex.test(namespace);
    }

};