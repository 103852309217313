import { Edit, SimpleForm, TextInput } from "react-admin";
import { EditToolbar } from "../CustomToolbar/EditToolbar";

export const SettingEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="id" disabled />
      <TextInput source="value" required />
      <TextInput source="etag" disabled />
    </SimpleForm>
  </Edit>
);
