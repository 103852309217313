import {createTheme} from "@mui/material/styles";
import {defaultTheme} from "react-admin";


export const MappingTheme = createTheme({
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiInputBase: {
            styleOverrides: {
                input: {
                    fontSize: '0.75rem',
                    width: '20rem',
                    height: '1rem'
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    fontSize: '0.75rem',
                },
            },
        },
    },
});