import { UserMenu, AppBar, Layout } from "react-admin";
import { SignOutButton } from "../components/auth/SignOutButton";
import Menu from "./menu";

const CustomUserMenu = () => (
  <UserMenu>
    <SignOutButton />
  </UserMenu>
);
const CustomAppBar = () => <AppBar userMenu={<CustomUserMenu />} />;
const CustomLayout = (props: any) => (
  <Layout {...props} appBar={CustomAppBar} menu={Menu} />
);

export { CustomUserMenu, CustomAppBar, CustomLayout };
